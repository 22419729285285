import React, {useState} from 'react'
import { Content, Modal, Button, Form } from 'react-bulma-components'
import { isMobile } from 'react-device-detect';
import axios from 'axios';

const ChangeModal = (props) => {

  const [code, setCode] = useState('none');
  const [errorCode, setErrorCode] = useState(false);

  const closeModal = () => {
    props.setActive(false);
    setErrorCode(false);
  }

  const checkCode = async(e) => {
    const result = await axios.post(`/api/projects/codeCheck/${props.id}`, code);

    if(result.data===true){
      if(props?.pending){
        props.action(props.id, code, true, props.pending);
      }else{
        props.action(props.id, code, true, false);
      }
      
    }else{
      setErrorCode(true);
    }
  }

  return (
  
  <div>
    <Modal show={props.active ? true : false} onClose={()=>{closeModal(); }}>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>
            { isMobile ? "Edit/Delete Code:" : "Please Enter Your Edit/Delete Code:"}
          </Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <Content>
            <Form.Input onChange={(e)=>{setCode(e.target.value);}} />
          </Content>
          { errorCode ? <span style={{ color: 'red', fontSize: '12px'}}>Incorrect Code</span> : ""}
        </Modal.Card.Body>
        <Modal.Card.Footer align="right" >
          <Button color="success" onClick={()=> {checkCode();}}>
            Validate
          </Button>
          <Button color="danger" onClick={()=> {closeModal();}}>
            Cancel
          </Button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  </div>

  )
}

export default ChangeModal