import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../createProject/CreateProject.css';
import 'react-bulma-switch/dist/react-bulma-switch.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';

import BulmaTagsInput from "@creativebulma/bulma-tagsinput";
import ControlledSwitch from 'react-bulma-switch';
import {Columns, Form, Button, Icon, Content, Hero, Heading, Container, Card, Block, Message, Image} from 'react-bulma-components';

import { FaUser } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import WaitingModal from '../../components/waitingModal/WaitingModal';
import imageNotFound from '../../assets/image_not_found.png';

const CreateProject = (props) => {

  BulmaTagsInput.attach();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentState, setContentState] = useState(null);

  const [imageFileName, setImageFileName] = useState('');
  const [imageFilePreview, setImageFilePreview] = useState(null);
  const [pdfFileName, setPdfFileName] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [projectHasPriceRange, setProjectHasPriceRange] = useState(false);

  const [errorName, setErrorName] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorImageFile, setErrorImageFile] = useState('');
  const [errorPdfFile, setErrorPdfFile] = useState('');
  const [errorPrice, setErrorPrice] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [errorStatus, setErrorStatus] = useState('');
  const [errorIsOrderable, setErrorIsOrderable] = useState('');
  const [errorIsOpenSource, setErrorIsOpenSource] = useState('');

  const [pr, setPr] = useState({
    name: "",
    title: "",
    email: "",
    imageFile: null,
    pdfFile: null,
    status: "4",
    price: 0,
    description: "",
    stlDownloadLink: "",
    ddlOptions: "",
    isOrderable: false,
    isOpenSource: false,
    priceRange: [9999,9999]
  });

  const onInputChange = (e) => {
    setDisabled(false);
    setPr({...pr, [e.target.name]: e.target.value});
  };

  const onFileChange = (e) => {
    setDisabled(false);
    setPr({...pr, [e.target.name]: e.target.files[0]});
    
    if(e.target.name === 'imageFile'){
      setImageFileName(e.target.files[0].name);
      setImageFilePreview(URL.createObjectURL(e.target.files[0]));
    }else if(e.target.name === 'pdfFile'){
      setPdfFileName(e.target.files[0].name);
    }
  }; 
  
  const handleSubmit = async (e) => {

    setProcessing(true);

    e.preventDefault();

    setDisabled(true);

    if(pr.price < 0 ){
      setPr({...pr, price: 0});
    }

    const tagElements = document.getElementsByClassName('tag');
    const tags = new Array();
    for (let i = 0; i < tagElements.length; i++) {
      const tagValue = tagElements[i].textContent.trim();
      tags.push(tagValue);
    }
    pr.ddlOptions = tags;

    if(pr.priceRange[0].toString() === '' || pr.priceRange[0].toString() === 'NaN'){
      if(pr.priceRange[1].toString() === '' || pr.priceRange[1].toString() === 'NaN'){
        pr.priceRange = [-9999, -9999];
      }else{
        pr.priceRange[0] = -9999;
        
      }
    }
    if(pr.priceRange[1].toString() === '' || pr.priceRange[1].toString() === 'NaN'){
      if(pr.priceRange[0].toString() === '' || pr.priceRange[0].toString() === 'NaN'){
        pr.priceRange = [-9999, -9999];
      }else{
        pr.priceRange[1] = -9999;
      }
    }

    await axios
    .post("/api/projects/create", pr, {headers: {'Content-Type': 'multipart/form-data'}})
    .then( ()=> {
      navigate('/communityProjects', {state: {bannerMessage: "Your project was successfully created."}});
    })
    .catch(function (error){    

      if(error.response.data.fieldErrors){

        setProcessing(false);

        setErrorName("")
        setErrorEmail("")
        setErrorTitle("")
        setErrorDescription("")
        setErrorImageFile("")
        setErrorPdfFile("")
        setErrorPrice("")
        setErrorStatus("")
        setErrorIsOrderable(false)
        setErrorIsOpenSource(false)

        error.response.data.fieldErrors.forEach(fieldError => {
          if(fieldError.field === 'name'){
            setErrorName(fieldError.message);
          }
          if(fieldError.field === 'email'){
            setErrorEmail(fieldError.message);
          }
          if(fieldError.field === 'title'){
            setErrorTitle(fieldError.message);
          }
          if(fieldError.field === 'description'){
            setErrorDescription(fieldError.message);
          }
          if(fieldError.field === 'imageFile'){
            setErrorImageFile(fieldError.message);
          }
          if(fieldError.field === 'pdfFile'){
            setErrorPdfFile(fieldError.message);
          }
          if(fieldError.field === 'price' || fieldError.field === 'priceRange'){
            setErrorPrice(fieldError.message);
          }
          if(fieldError.field === 'status'){
            setErrorStatus(fieldError.message);
          }
          if(fieldError.field === 'isOrderable'){
            setErrorStatus(fieldError.message);
          }
          if(fieldError.field === 'isOpenSource'){
            setErrorStatus(fieldError.message);
          }
        });
      }
    })

    if(pr.priceRange[0] === -9999){
      pr.priceRange[0] = 0;
    }
    if(pr.priceRange[1] === -9999){
      pr.priceRange[1] = pr.priceRange[0];
    }

    setProcessing(false);
  };

  const clearForm = (e) => {
    e.preventDefault();
    setDisabled(false);
    navigate('/communityProjects')
  };

  const handleContentStateChange = (contentState) => {
    setContentState(draftToHtml(contentState));
    setPr({...pr, description: draftToHtml(contentState)});
    setDisabled(false);
  };

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    document.getElementsByName('imageFile')[0].setAttribute("accept","image/*");
    document.getElementsByName('pdfFile')[0].setAttribute("accept", ".pdf");

  });
 
  return (
    <div id='createProjectPage'>
    <WaitingModal active={processing} message="create"/>
    <Columns multiline={false}>
        <Columns.Column size={6} offset={3}>
          <Hero color={'dark'} size={'small'}>
            <Hero.Body>
              <Container>
                <Heading weight={'bold'}>
                  Create Your Project
                </Heading>
              </Container>
            </Hero.Body>
          </Hero>
        </Columns.Column>
    </Columns>
    <Columns multiline={false} centered="true">
      <Columns.Column size={8}>
        
        <Card>
          <Card.Header.Title>
            Project Creation Form:
          </Card.Header.Title>
          <Card.Content>
            <Content>
              <form onSubmit={ (e) => handleSubmit(e)} encType="multipart/form-data">
                <Form.Field>
                  <Form.Label required>Project Owner:</Form.Label>
                  <Block className='ddlWarning'>
                    <Message color={'warning'} size={'small'}>
                      <Message.Body>
                        <strong>Important: </strong> This will appear on your projects card which is visible to everyone. 
                      </Message.Body>
                    </Message>
                  </Block>
                  <Form.Control>
                    <Form.Input
                      name='name'
                      value={pr.name}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                    <Icon align="left" size="small">
                      <FaUser />
                    </Icon>
                  </Form.Control>
                  { errorName ? <span style={{ color: 'red', fontSize: '12px'}}>{errorName}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Label>Contact Email:</Form.Label>
                  <Block className='ddlWarning'>
                    <Message color={'warning'} size={'small'}>
                      <Message.Body>
                        <strong>Important: </strong> This will appear on your projects card which is visible to everyone. 
                      </Message.Body>
                    </Message>
                  </Block>
                  <Form.Control>
                    <Form.Input
                      name='email'
                      value={pr.email}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                    <Icon align="left" size="small">
                      <MdEmail />
                    </Icon>
                  </Form.Control>
                  { errorEmail ? <span style={{ color: 'red', fontSize: '12px'}}>{errorEmail}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Label>Project Title:</Form.Label>
                  <Form.Control>
                    <Form.Input
                      name='title'
                      value={pr.title}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                  </Form.Control>
                  { errorTitle ? <span style={{ color: 'red', fontSize: '12px'}}>{errorTitle}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Label>Project Image:</Form.Label>
                  <Form.Control>
                    {imageFilePreview && <Image src={imageFilePreview} fallback={imageNotFound} alt="Project Image" size={128}/>}
                    <Form.InputFile color={'primary'}  type="file" name='imageFile' filename={imageFileName} fullwidth="true" onChange={(e) => {onFileChange(e);}} />
                  </Form.Control>
                  { errorImageFile ? <span style={{ color: 'red', fontSize: '12px'}}>{errorImageFile}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Label>Project PDF:</Form.Label>
                  <Form.Control>
                    <Form.InputFile color={'primary'}  type="file" name='pdfFile' filename={pdfFileName} fullwidth="true" onChange={(e) => {onFileChange(e);}} />
                  </Form.Control>
                  { errorPdfFile ? <span style={{ color: 'red', fontSize: '12px'}}>{errorPdfFile}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Label>Project Status:</Form.Label>
                  <Form.Control>
                  <Form.Select
                      name='status'
                      value={pr.status}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    >
                      <option value="0">COMPLETED / NO CHANGES</option>
                      <option value="1">TESTING / MINIMAL CHANGES</option>
                      <option value="2">ACTIVE DEVELOPMENT / CHANGES EXPECTED</option>
                      <option value="3">DEPRECATED / OLD</option>
                      <option value="4">Please Select One</option>
                    </Form.Select>
                  </Form.Control>
                  { errorStatus ? <span style={{ color: 'red', fontSize: '12px'}}>{errorStatus}</span> : ""}
                </Form.Field>
                <Form.Field style={{marginBottom: "0"}}>
                  <Form.Label>Brief Description:</Form.Label>
                  <Block className='ddlWarning'>
                    <Message color={'info'} size={'small'}>
                      <Message.Body>
                        <strong>Please Note: </strong>Adding formatting counts toward your character count.
                      </Message.Body>
                    </Message>
                  </Block>
                  <div>
                    <Editor 
                      editorState={editorState}
                      toolbarClassName="editor-toolbar"
                      wrapperClassName="editor-wrapper"
                      editorClassName="editor"
                      onEditorStateChange={handleEditorStateChange}
                      onContentStateChange={handleContentStateChange}
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history']
                      }}
                    />
                  </div>
                  { errorDescription ? <span style={{ color: 'red', fontSize: '12px'}}>{errorDescription}</span> : ""}
                </Form.Field>
                <div className='descriptionCount'><span style={{color: pr.description.length > 3000 ? "red" : "black"}}>{pr.description.length === 8 ? 0 : pr.description.length}</span><span>/3000 characters</span></div>
                <Form.Field>
                  <Form.Label>Can this project be ordered?</Form.Label>
                  <div className='toggleButton'>
                    <ControlledSwitch value={pr.isOrderable} thin={true} onChange={(e) => {setPr({...pr, isOrderable: (!pr.isOrderable)});}}/> 
                    {pr.isOrderable?<span> Yes</span>:<span> No</span>}
                  </div>
                  { errorIsOrderable ? <span style={{ color: 'red', fontSize: '12px'}}>{errorIsOrderable}</span> : ""}
                </Form.Field>
                { pr.isOrderable &&
                <Form.Field>
                  <Form.Label>Price:</Form.Label>
                  <Block className='ddlWarning'>
                    <Message color={'info'} size={'small'}>
                      <Message.Body>
                        Does the project have a single price (e.g. $25) or price range (e.g. $25 - $50)? 
                      </Message.Body>
                    </Message>
                  </Block>
                  <div className='toggleButton' style={{height: '20px', marginBottom: "0.75rem"}}>
                    <ControlledSwitch 
                      value={projectHasPriceRange} 
                      thin={true} 
                      onChange={(e) => {
                        setDisabled(false);
                        setProjectHasPriceRange(!projectHasPriceRange);
                        if(projectHasPriceRange===false){
                          setPr({...pr, price: 9999, priceRange: [0, 0]});
                        }else{
                          setPr({...pr, price: 0, priceRange: [9999, 9999]});
                        }
                      }}
                    /> 
                    {projectHasPriceRange?<span> Price Range</span>:<span> Single Price</span>}
                  </div>
                  <Form.Control style={{display: "flex"}}>
                  { projectHasPriceRange ?
                  <>
                  <div style={{margin: "5px", width: "30%"}}>
                  <Form.Label style={{fontSize: "13px", fontWeight: 600}}>Min:</Form.Label>
                  <Form.Input
                    value={pr.priceRange[0]} 
                    type='number'
                    onChange={(e) => {
                      setDisabled(false);
                      setPr({...pr, priceRange: [e.target.valueAsNumber, pr.priceRange[1] ]});
                    }}
                  />
                  </div>
                  
                  <div style={{margin: "5px", width: "30%"}}>
                  <Form.Label style={{fontSize: "13px", fontWeight: 600}}>Max:</Form.Label>
                  <Form.Input
                    value={pr.priceRange[1]}
                    type='number'
                    onChange={(e) => {
                      setDisabled(false);
                      setPr({...pr, priceRange: [pr.priceRange[0], e.target.valueAsNumber]});
                    }}
                  />
                  </div>
                  </>
                  :
                    <Form.Input style={{width: "60%"}}
                      name='price'
                      value={pr.price}
                      type='number'
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                  }
                  </Form.Control>

                  { errorPrice ? <span style={{ color: 'red', fontSize: '12px'}}>{errorPrice}</span> : ""}
                </Form.Field>
                }
                <Form.Field>
                  <Form.Label>Is this project open-source?</Form.Label>
                    <Block className='ddlWarning'>
                      <Message color={'info'} size={'small'}>
                        <Message.Body>
                          An open-source project is a project that anybody is free to use, study, modify, and make for themselves for any purpose free.
                          However you can still make and sell an open-source project in case people would rather buy one instead of make one. 
                          You can include a link to an external site or the project's source below but it is not manditory. 
                        </Message.Body>
                      </Message>
                    </Block>
                  <div className='toggleButton'>
                    <ControlledSwitch value={pr.isOpenSource} thin={true} onChange={(e) => {setPr({...pr, isOpenSource: (!pr.isOpenSource)});}}/>
                    {pr.isOpenSource?<span> Yes</span>:<span> No</span>}
                  </div>
                  { errorIsOpenSource ? <span style={{ color: 'red', fontSize: '12px'}}>{errorIsOpenSource}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Label>External Link / STL File Download Link:</Form.Label>
                  <Form.Control>
                    <Form.Input
                      name='stlDownloadLink'
                      value={pr.stlDownloadLink}
                      type='url'
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                  </Form.Control>
                </Form.Field>
                <Form.Field>
                  <Form.Label>Project - Types/Styles/Options - (Max of 5)</Form.Label>
                    <Block className='ddlWarning'>
                      <Message color={'info'} size={'small'}>
                        <Message.Body>
                          To add a project Type/Style/Option enter the text that you want to appear in a drop-down list and press Enter. <br/><br/>
                          They will appear below as a green tag. <br/><br/>
                          These options will appear in you project's order page as a drop-down list for people to select when ordering. <br/><br/>
                          <strong>RECOMMENDATION:</strong> Provide your options in the following format e.g. option 1 - $20.00. 
                          This is optional but it will allow the site to use the options price in your projects order page.
                        </Message.Body>
                      </Message>
                    </Block>
                    <Form.Control>
                    <Form.Input id='input-tags' className="tag-input-list" type="text" data-type='tags' data-max-tags="5"  value={pr.ddlOptions} onChange={(e) => {}}/>
                  </Form.Control>
                </Form.Field>
                <Form.Field >
                  <Button type='submit' color="success" disabled={disabled}>
                    Create
                  </Button>
                  <Button color="danger" onClick={(e) => {clearForm(e)}} >
                    Cancel
                  </Button>
                </Form.Field>
              </form>
            </Content>
          </Card.Content>
        </Card>
      </Columns.Column>
    </Columns>
    </div>
  )
}


export default CreateProject