import React from 'react'

import { Block, Message, Columns } from 'react-bulma-components'

const NoContent = () => {
  return (
    <Columns.Column offset={2} size={8} >
      <Block>
        <Message color={'info'}>
          <Message.Body size={8} > 
            No content at this time, check back later.
          </Message.Body>
        </Message>
      </Block>
    </Columns.Column>
  )
}

export default NoContent