import React, {useState} from 'react'
import { Modal, Button } from 'react-bulma-components'
import axios from 'axios';

const TireChalkDeleteReportModal = (props) => {

  const closeModal = () => {
    props.setActive(false);
  }

  const deleteTireChalkReport = async(tireChalkReport) => {
    {props.isAdmin ? 
      await axios.put(`/api/v1.2/tireChalk/admin/deleteTireChalkReport`, 
        {...tireChalkReport, trustedUserPassword: props.currentUserPassword},
        {
          headers: {
            'Authorization': `Bearer ${props.jwt}`
          }
        }
      ).then((res) => {props.getTireChalkReports(); closeModal(); props.showBannerMessage('delete');})
      .catch(function (error){
        props.showBannerMessage('unauthorized');
        closeModal();
      })
    :
      await axios.put(`/api/v1.2/tireChalk/deleteTireChalkReport`, {...tireChalkReport, trustedUserPassword: props.currentUserPassword})
      .then((res) => {props.getTireChalkReports(); closeModal(); props.showBannerMessage('delete');})
      .catch(function (error){
        props.showBannerMessage('unauthorized');
        closeModal();
      });
    }
  }

  return (
  
  <div>
    <Modal show={props.active ? true : false} onClose={()=>{closeModal();}}>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>
            Do you really want to Delete the report?
          </Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Footer align="right" >
          <Button color="success" onClick={()=> {deleteTireChalkReport(props.report)}}>
            Delete
          </Button>
          <Button color="danger" onClick={()=> {closeModal();}}>
            Cancel
          </Button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  </div>

  )
}

export default TireChalkDeleteReportModal