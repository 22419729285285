import React from 'react';
import '../logout/Logout.css';

import { Form, Button, Card, Columns, Content } from 'react-bulma-components';
import { useNavigate } from 'react-router-dom';
import useTokenStore  from '../../utils/store';

const Logout = (props) => {

  const navigate = useNavigate();
  const jwt = useTokenStore((state) => state.jwt);
  const setJwt = useTokenStore((state) => state.setJwt);

  const handleSubmit = (e) => {
    e.preventDefault();
    setJwt("");
    navigate('/login');
  }

  return (
    <div id="logoutPage">
      <Columns multiline={false} centered vCentered>
        <Columns.Column size={4}>
          <Card id='logoutForm'>
            <Card.Header.Title>
              Admin Logout:
            </Card.Header.Title>
            <Card.Content>
              <Content>
                <form onSubmit={handleSubmit}>
                  <Form.Field >
                  <Button type='submit' color="danger">
                    Logout
                  </Button>
                </Form.Field>
                </form>
              </Content>
            </Card.Content>
          </Card>
        </Columns.Column>
      </Columns>
    </div>
  )
}

export default Logout