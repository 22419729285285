import React, { useState, useEffect, } from 'react';
import axios from 'axios';
import '../admin/ViewProjectAdmin.css';
import 'react-bulma-switch/dist/react-bulma-switch.min.css';

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useNavigate, useParams } from 'react-router-dom';
import { Columns, Content, Card, Button, Form } from 'react-bulma-components';
import ControlledSwitch from 'react-bulma-switch';

import { FaCartArrowDown, FaExternalLinkAlt } from 'react-icons/fa';
import { GrDocumentPdf } from 'react-icons/gr';
import { isMobile } from 'react-device-detect';

import ViewPdf from '../viewPdf/ViewPdf';
import Spinner from '../../components/loadingSpinner/Spinner';
import WaitingModal from '../../components/waitingModal/WaitingModal';
import useTokenStore from '../../utils/store';

const ViewProjectAdmin = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { promiseInProgress } = usePromiseTracker();
  const jwt = useTokenStore((state) => state.jwt);

  const [togglePdfView, setTogglePdfView] = useState(false);
  const [downloadable, setDownloadable] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [approval, setApproval] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [comments, setComments] = useState({
    comments: "",
    action: 0
  });

  const [project, setProject] = useState({
    title: "",
    description: "",
    name: "",
    email: "",
    price: "",
    priceRange: [],
    pdfPath: "",
    imagePath: "",
    stlDownloadLink: "",
    status: "",
    isOrderable: false,
    isOpenSource: false,
    approved: false,
    editCode: "",
    ddlOptions: [""],
    itcProject: false
  });

  useEffect(() => {
    trackPromise(loadProject());
  }, []);

  const loadProject = async () => {
    const result = await axios.get(`/api/admin/projects/view/${id}`, {
      headers: {
          'Authorization': `Bearer ${jwt}`
      }
    });
    setProject(result.data);
    if(result.data.stlDownloadLink !== ''){
      setDownloadable(true);
    }
  };

  const moreDetailsClicked = () => {
    setTogglePdfView(!togglePdfView);
  }

  const deleteProject = async (e) => {
    e.preventDefault();
    setProcessing(true);
    await axios.post(`/api/admin/projects/delete/${id}`,  comments,
              {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
              })
              .then(()=>{navigate("/communityProjects", {state: {bannerMessage: "The project was successfully nuked. We are sorry to see the project leave the platform."}});})
              .catch(error => {console.log(error);});
  }

  const itcProject = async(e) => {
    e.preventDefault();
    setLoadingToggle(true);
    await axios.post(`/api/admin/projects/toggleToItc/${id}`, {} ,{
      headers: {
          'Authorization': `Bearer ${jwt}`
      }
    }).then((res)=>{ setProject({...project, itcProject: !project.itcProject}); });
    loadProject();
    setLoadingToggle(false);
  }

  const toggleProjectApproval = async(e) => {
    e.preventDefault();
    setLoading(true);
    await axios.post(`/api/admin/projects/toggleApproval/${id}`, comments ,{
      headers: {
          'Authorization': `Bearer ${jwt}`
      }
    }).then(()=>{setProject({...project, approved: !(project.approved)}); });

    setApproval(false); 
    setComments({...comments, comments: "", action: 0});
    setLoading(false);
  }

  const getBackClickedState = (backClicked) => {
    backClicked ? setTogglePdfView(false) : setTogglePdfView(true);
  }

  const cardStyle = {
    margin: 'auto',
  };

  return (
    
    <div id='viewProjectAdminPage' >
      {promiseInProgress ?
        <Spinner/>
      :
        <>
          <WaitingModal active={processing} message="delete"/>
        <>
        {togglePdfView?
          <ViewPdf projectFile={project.pdfPath} func={getBackClickedState}/>
        :
          <>
          <Columns centered="true" multiline={false}>
            <Columns.Column size={10} id='viewProjectColumn'>
              <Card style={cardStyle}>
                <Card.Header>
                  <Card.Header.Title>
                    {project.title}
                  </Card.Header.Title>
                  <Card.Header.Icon>
                    {downloadable ? <span><a href={project.stlDownloadLink}><FaExternalLinkAlt size={35} id='download'/></a></span> : '' }
                  </Card.Header.Icon>
                </Card.Header>
                <Card.Content>
                  <div id='projectDetails'>
                    <Content>
                      <div className='columns'>
                            <Columns.Column size={6}>
                              <Card.Image size="4by3" alt="Project Card Image" style={cardStyle} src={`/project_images/${project.imagePath}`}/>
                            </Columns.Column>
                            <Columns.Column size={6}>
                            <span className="title is-6">Edit-Code: </span>{project.editCode}<br/><br/>
                              {project.isOpenSource && <><span className='free'>OPEN-SOURCE PROJECT</span><br/><br/></> }
                              <span className="title is-6">Project Owner: </span>{project.name}<br/><br/>
                              <span className="title is-6">Contact Email: </span><a href={"mailto:"+project.email}>{project.email}</a><br/><br/>
                              {project.price === 9999 ? 
                                <><span className="title is-6">Price: </span>{"$"+ project.priceRange[0] + " - " + "$" + project.priceRange[1]}<br/><br/></>
                              :
                                <><span className="title is-6">Price: </span>{project.price===0 ? <span> FREE</span> : "$"+project.price}<br/><br/></>
                              }
                              <span className="title is-6">Project Status: </span>
                                {project.status===0 && <span><strong className='statusCompleted'>COMPLETED / NO CHANGES</strong></span> }
                                {project.status===1 && <span><strong className='statusTesting'>TESTING / MINIMAL CHANGES</strong></span> }
                                {project.status===2 && <span><strong className='statusActive'>ACTIVE DEVELOPMENT / CHANGES EXPECTED</strong></span> }
                                {project.status===3 && <span><strong className='statusDepricated'>DEPRECATED / OLD </strong></span>}
                                <br/><br/>
                              <span className="title is-6">Brief Description: </span><br/><div className='descriptionBox' dangerouslySetInnerHTML={{ __html: project.description }} /><br/>
                              {downloadable && 
                              <>
                              <span className="title is-6">Project's External Link: </span><br/><span><a href={project.stlDownloadLink}>{project.stlDownloadLink}</a></span><br/><br/>
                              </>}
                              {project.ddlOptions &&
                              <>
                              <span className="title is-6">Options: </span>
                              <ol>
                                {project.ddlOptions.map( option =>(<li key={option}>{option}</li>))}
                              </ol>
                              </>}
                            </Columns.Column>
                        </div>
                    </Content>
                  </div>
                </Card.Content>
                  <footer className="card-footer">
                    { project.pdfPath === "" ? "" :<Button className='card-footer-item' onClick={(e) => moreDetailsClicked()}><GrDocumentPdf className='pdfFooterIcon'/>{isMobile ? "" : "More Details"}</Button>}
                    { project.isOrderable ? <Button disabled={true} className='card-footer-item' ><FaCartArrowDown color='black'className='cartFooterIcon'/>{isMobile ? "" : "Order"}</Button> : "" }
                  </footer>
              </Card>
              <br/>
              { !approval &&
              <Card id='adminControls'>
                <Card.Header>
                  <Card.Header.Title>
                    Admin Controls:
                  </Card.Header.Title>
                  <Card.Header.Icon>
                    <Button className='deleteButton' color={"danger"} onClick={()=>{setShowDelete(true); setApproval(true);}}>Delete</Button>
                  </Card.Header.Icon>
                </Card.Header>
                <Card.Content>
                <Columns>
                  {project.approved ? 
                    <Button color={"danger"} onClick={()=>{setApproval(true);}}>Revoke</Button> 
                  :
                    <Button color={"primary"} onClick={()=>{setApproval(true);}}>Review</Button>
                  }
                  <Button type='button' onClick={()=>{navigate("/admin");}} color="info">Back</Button>
                </Columns>
                <Columns>
                <div>
                  <Form.Label>ITC Project:</Form.Label>
                  <ControlledSwitch value={project.itcProject} thin={true} disabled={loadingToggle ? true : false}
                    onChange={(e) => {
                      itcProject(e);
                    }}/> 
                  </div>
                </Columns>
                </Card.Content>
              </Card>
              }
              <br/>
              { loading ? <WaitingModal active={loading} message="submit a change for"/> :
               approval &&
                <Card id='adminActionCard'>
                <Card.Header>
                  <Card.Header.Title>
                    {showDelete===true ? "Delete Project" : project.approved ? "Revoke Project:" : "Approve Project:" }
                  </Card.Header.Title>
                </Card.Header>
                <Card.Content>
                  <form onSubmit={(e)=>{ showDelete ? deleteProject(e) : toggleProjectApproval(e) }}>
                    <Form.Field>
                      <Form.Label>Comments:</Form.Label>
                      <Form.Textarea
                        name="comments"
                        value={comments.comments}
                        onChange={(e) => {
                          setComments({...comments, comments: e.target.value});
                        }}
                      />
                    </Form.Field>
                    <Form.Field >
                      <Button type='submit' color="success" onClick={()=>{ setComments({...comments, action: project.approved ? 2 : 0});}}>
                        {showDelete ? "Delete Project" : project.approved ? "Submit Project Revocation" : "Submit Project Approval"}
                      </Button>
                      {!showDelete &&
                        !project.approved &&
                        <Button type='submit' color="warning" onClick={()=>{setComments({...comments, action: 1});}}>
                          Reject Project
                        </Button>
                      }
                      <Button type='button' onClick={()=>{setApproval(false); setShowDelete(false);}} color="danger">
                        Cancel
                      </Button>
                    </Form.Field>
                  </form>
                </Card.Content>
              </Card>
              }
            </Columns.Column>
          </Columns>
          </>
        }
        </>
      </>
      }
      
    </div>
  )
}

export default ViewProjectAdmin