import React, { useEffect, useState }  from 'react';
import useTokenStore from './store';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export default function useVerifyToken() {

  const jwt = useTokenStore(state => state.jwt);
  const [pass, setPass] = useState(false);

  const checkToken = async() => {
    await axios.get(`/api/auth/validate?token=${jwt}`, {
      headers: {
          'Authorization': `Bearer ${jwt}`
      }
    }).then(() => {
      setPass(true);
    }).catch(() => {
      setPass(false);
    });
  }

  useEffect(()=>{
    if(jwt !== ""){
      checkToken();
    }
  },[jwt])

  if(jwt !== ""){
    if(pass){
      return pass;
    }
  }else{

    return false;
  }
}

export function useIsAdmin() {
  const jwt = useTokenStore(state => state.jwt);
  const [isAdmin, setIsAdmin] = useState(false);
  if(jwt !== ""){
    if ((["ROLE_80085"]).every(role => (jwt_decode(jwt).roles).includes(role))){
      if(!(jwt_decode(jwt).exp * 1000 < Date.now())){
        setIsAdmin(true);
      }
    }
  }
  if(useVerifyToken() === true){
    return isAdmin;
  }
  return false;
}

export const RequiredAuthRoles = ({children, allowedRoles}) => {
  
  const jwt = useTokenStore(state => state.jwt);
  const [rolesMatch, setRolesMatch] = useState(false);

  const hasRoles = () => {
    if(jwt !== ""){
      if ((allowedRoles).every(role => (jwt_decode(jwt).roles).includes(role))){
        if(!(jwt_decode(jwt).exp * 1000 < Date.now())){
          setRolesMatch(true);
        }
        
      }else{
        setRolesMatch(false);
      }
    }
  }

  useEffect(()=>{

    hasRoles();
    
  })

  if(useVerifyToken() === true){
    return  (rolesMatch === true) ? children : <></> 
  }
  return <></>

}