import React, { useState, useEffect } from 'react';
import "../admin/Admin.css";
import { Columns,  Hero, Container, Heading, Table, Box, Button } from 'react-bulma-components';
import { isFirefox, isMobile } from 'react-device-detect';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/loadingSpinner/Spinner';
import NoContent from '../../components/noContent/NoContent';
import axios from 'axios';
import useTokenStore from '../../utils/store';

const Admin = () => {
  const navigate = useNavigate();
  const jwt = useTokenStore((state) => state.jwt);
  const [projects, setProjects] = useState([]);
  const [noContent, setNoContent] = useState(false);
  const { promiseInProgress } = usePromiseTracker();
  
  const getProjects = async() => {
    await axios.get(`/api/admin/projects`, {
      headers: {
        'Authorization': `Bearer ${jwt}`
      }
    }).then((res) => setProjects(res.data));
  }

  const showProject = (id) => {
    navigate(`projects/${id}`)
  }

  useEffect(() => {
      trackPromise(getProjects());
  },[])

  useEffect(() => {
    if(projects.length > 0){
      setNoContent(false);
    }else{
      setNoContent(true);
    }
  },[projects])

  return (
    <div id='adminPage'>
    <Columns multiline={false}>
        <Columns.Column size={6} offset={3}>
          <Hero color={'dark'} size={'small'}>
            <Hero.Body>
              <Container>
                <Heading weight={'bold'}>
                  I2C Admin Page
                </Heading>
              </Container>
            </Hero.Body>
          </Hero>
        </Columns.Column>
    </Columns>
    <Columns.Column centered="true">
      
      {promiseInProgress ?
      <Spinner/>
      :
      <>
      {noContent ? <NoContent /> : <></>}
      <Columns className={isFirefox ? "ff": ""} centered="true" size={10}>
        <>
        <Box>
          <Container>
          <Table className='is-fullwidth is-hoverable' >
            <thead>
            <tr>
              <th><abbr title="Project ID">ID</abbr></th>
              <th><abbr title="Project Title">Title</abbr></th>
              {!isMobile &&
              <>
              <th><abbr title="Project Owner">Owner</abbr></th>
              <th><abbr title="Project Email">Email</abbr></th>
              <th><abbr title="Project Modify Code">Modify Code</abbr></th>
              <th><abbr title="Project Status">Status</abbr></th>
              </>
              }
              <th></th>
            </tr>
            </thead>
            <tbody>
            {projects.map(project => ( 
              <tr key={project.id} className={"data"} >
                <td>{project.id}</td>
                <td>{project.title}</td>
                {!isMobile &&
                <>
                <td>{project.name}</td>
                <td>{project.email}</td>
                <td style={{cursor: 'pointer'}} onClick={() => {navigator.clipboard.writeText(project.editCode)}}>{project.editCode}</td>
                <td style={project.approved ? { color: 'green'} : {color: 'red'}}>{project.approved ? "Approved" : "Pending"}</td>
                </>
                }
                <td><Button className='is-small' color={project.approved ? 'warning' : 'info'} onClick={() => showProject(project.id)}>Review</Button></td>
              </tr>
            ))}
            </tbody>
          </Table></Container>
        </Box>
        </>
      </Columns>
      </>
      }
    </Columns.Column>
    </div>
  )
}

export default Admin