import React, { useState, useEffect } from 'react';
import '../tireChalk/TireChalk.css'

import { useNavigate } from 'react-router-dom';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import useIsAdmin from '../../utils/util';
import useTokenStore from '../../utils/store';
import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';

import { Form, Button, Icon, Card, Columns, Hero, Container, Heading, Content, Block, Message, Box, Table } from 'react-bulma-components';
import Spinner from '../../components/loadingSpinner/Spinner';
import { isMobile} from 'react-device-detect';
import SuccessBanner from '../../components/successBanner/SuccessBanner';
import TireChalkTable from './TireChalkTable';

import { fetchToken, onMessageListener, deleteFcmToken, areNotificationsSupported } from '../../firebase';

import { 
  FaRoad,
  FaClock,
  FaComments,
  FaDoorClosed,
  FaDoorOpen
} from 'react-icons/fa';

import { 
  MdNotificationImportant
} from 'react-icons/md';

import i2cIconLowRes from '../../assets/i2c_background_lr.jpg';

import TrustedUserModal from './TrustedUserModal';
import TireChalkFormStreetSelect from './TireChalkFormStreetSelect';

const notifyUser = async(message = "Cheers - TireChalk Notifications Enabled!", body, icon = i2cIconLowRes) => {
  navigator.serviceWorker.register('sw.js');
  if(("Notification" in window)){
    if(Notification.permission === "granted" ){
      //const notification = new Notification(message, {body: body, icon: icon, requireInteraction: true});
      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification(message, {body: body, icon: icon, requireInteraction: "true", url: "https://i2c.space/tireChalk"});
      });

    } else if (Notification.permission !== "denied"){
      await Notification.requestPermission().then((permission) => {
        if(permission === "granted"){
          // const notification = new Notification(message, );
          console.log("Tire Chalk Notidications - enabled");
        }
      })
    }
  }
}

const TireChalk = () => {
  
  const { promiseInProgress } = usePromiseTracker();

  const [hideAdminMessages, setHideAdminMessages] = useState(localStorage.getItem('hideAdminMessages') || false);
  const [notificationsOn, setNotificationsOn] = useState(Notification.permission==="granted" ? true : false);
  const [disabledNotifications, setDisabledNotifications] = useState(localStorage.getItem("notificationsDisabled")==="false" ? false : true || undefined )
  const [fcmClientToken, setFcmClientToken] = useState(localStorage.getItem('fcmClientToken') || null);
  const [fcmClientLocation, setFcmClientLocation] = useState(parseInt(localStorage.getItem('fcmTokenLocation')));
  const [initialLoad, setInitialLoad] = useState(true);
  const [notificationsSupported, setNotificationsSupported] = useState(false);

  const isAdmin = useIsAdmin();
  const [isVerySmallWindow, setIsVerySmallWindow] = useState(false);
  const [messageBanner, setMessageBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerColor, setBannerColor] = useState("success");
  const [showChalkReportForm, setShowChalkReportForm] = useState(false);
  const [streets, setStreets] = useState(['uc','uc','uc','uc','uc','uc','uc','uc','uc']);
  const [reports, setReports] = useState([]);
  const [mobileReports, setMobileReports] = useState([]); 
  const [sessionCount, setSessionCount] = useState(0);
  const [currentLocation, setCurrentLocation] = useState(parseInt(localStorage.getItem('currentLocation')) || 0);
  
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentUser, setCurrentUser] = useState('none');
  const [currentUserPassword, setCurrentUserPassword] = useState('none');
  const jwt = useTokenStore((state) => state.jwt);

  const [timeError, setTimeError] = useState("");
  const [commentsError, setCommentsError] = useState("");

  const [streetNames, setStreetNames] = useState([]);

  const [sessionCounts, setSessionCounts] = useState({
    hsSessionCount: 0,
    lsSessionCount: 0
  });

  const [tireChalkReport, setTireChalkReport] = useState({
    id: null,
    streetStatuses: [],
    comments: "",
    time: "00:00",
    reportDate: null,
    buildingLocation: 0,
    trustedUserReport: false,
    trustedUserId: '',
    trustedUserPassword: '',
    sessionUUID: ''
  });

  const [parkingBan, setParkingBan] = useState({
    status: 0,
    message: ""
  });

  const [subscriptionRequest, setSubscriptionRequest] = useState({
    buildingLocation: 0,
    sessionId: '',
    fcmToken: ''
  });

  const updateStreetStatus = (i, value) => {
    let items = streets;
    let item = {...items[i]};
    item = value;
    items[i] = item;
    setStreets(items);
  };

  const enableNotifsAndClose = async () => {

    let check = await areNotificationsSupported();

    if(check){
      await notifyUser().then(() => {
        setNotificationsOn(true);
      });
      setDisabledNotifications(false);
      localStorage.setItem("notificationsDisabled", false);

      if(localStorage.getItem('fcmClientToken') === null){
        setInitialLoad(false);
        fetchToken(setFcmClientToken);
      }else{
        setFcmClientLocation(parseInt(localStorage.getItem("savedFcmTokenLocation")) || currentLocation);
        setFcmClientToken(localStorage.getItem('fcmClientToken'));
        localStorage.setItem("fcmTokenLocation", fcmClientLocation);
      }
    }
  }

  const toggleNotificationLocation = async () => {
    await axios.post("/api/v1.2/tireChalk/toggleTokensNotificationLocation", fcmClientToken).then((res)=>{localStorage.setItem('fcmTokenLocation', currentLocation); localStorage.setItem("savedFcmTokenLocation", currentLocation); console.log("Subcribed token location changed");}).catch( (e) => {});
  }
  
  useEffect(() => {
    if(fcmClientToken !== null && !initialLoad){
      let request = subscriptionRequest;
      request.fcmToken = fcmClientToken;
      request.buildingLocation = localStorage.getItem("savedFcmTokenLocation") || fcmClientLocation || currentLocation;
      request.sessionId = localStorage.getItem("sessionUUID");
      setSubscriptionRequest(request);
      axios.post("/api/v1.2/tireChalk/subscribe", subscriptionRequest).then((res)=>{console.log("Subscribed to " + (currentLocation==="1" ? "LS Notifications" : "HS Notifications"));}).catch( (e) => {});
      setFcmClientLocation(parseInt(localStorage.getItem("savedFcmTokenLocation")) || currentLocation);
      setFcmClientToken(localStorage.getItem('fcmClientToken'));
      localStorage.setItem("fcmTokenLocation", localStorage.getItem("savedFcmTokenLocation") || fcmClientLocation || currentLocation);
    }
  }, [fcmClientToken]);

  const disableNotifsAndClose = async () => {
    setNotificationsOn(false);
    unsubscribeFcmToken(fcmClientToken);
    localStorage.setItem("savedFcmTokenLocation", fcmClientLocation || localStorage.getItem('fcmTokenLocation'));
    deleteFcmToken();
    setSubscriptionRequest({buildingLocation: currentLocation, sessionId: "", fcmToken: null});
    setFcmClientToken(null);
    setDisabledNotifications(true);
    localStorage.setItem("notificationsDisabled", true);
  }

  const unsubscribeFcmToken = async(token) => {
    await axios.post("/api/v1.2/tireChalk/unsubscribe", token).then((res)=>{console.log("Unsubscribed token");}).catch( (e) => {});
  }

  useEffect(() => {
    if(Notification.permission !== "granted" && fcmClientToken !== null){
      unsubscribeFcmToken(fcmClientToken);
    }
  }, [Notification.permission]);



  const getCurrentTime = () => {
    let report = tireChalkReport;
    let date = new Date;
    let currentTime = ""
    if(date.getHours() < 10){
      currentTime = "0" + date.getHours() + ":";
    }else{
      currentTime = date.getHours() + ":";
    }
    if(date.getMinutes() < 10){
      currentTime = currentTime + "0" + date.getMinutes();
    }else{
      currentTime = currentTime + date.getMinutes();
    }
    report.time = currentTime;
    setTireChalkReport(report);
  }

  const showBannerMessage = (type) => {
    setMessageBanner(true);
    if(type === "create"){
      setBannerColor("success");
      setBannerMessage("New Tire Chalk Entry Added! Thank you for contributing.");
      setTimeout(() => {setMessageBanner(false)}, 7000);
    } else if(type === "unauthorized") {
      setBannerColor("danger");
      setBannerMessage("Looks like you are not authorized to perform that action!");
      setTimeout(() => {setMessageBanner(false)}, 30000);
    } else if(type === "edit"){
      setBannerColor("success");
      setBannerMessage("Tire Chalk Entry has been updated!");
      setTimeout(() => {setMessageBanner(false)}, 7000);
    }else{
      setBannerColor("success");
      setBannerMessage("Requested Tire Chalk Entry Deleted!");
      setTimeout(() => {setMessageBanner(false)}, 7000);
    }
  }

  const generateSessionUUID = () => {
    if(localStorage.getItem("sessionUUID") === null){
      localStorage.setItem('sessionUUID', uuidv4());
    }
  }

  const clearForm = (e) => {
    e.preventDefault();
    setTireChalkReport({id: null, streetStatuses: [], comments: "", time: "00:00", buildingLocation: currentLocation, trustedUserId: currentUser, trustedUserPassword: currentUserPassword, trustedUserReport: false});
    setStreets(currentLocation === 0 ? ['uc','uc','uc','uc','uc','uc','uc','uc','uc'] : ['uc','uc','uc','uc','uc','uc','uc','uc']);
  };

  const updateMobileReport = async() => {
    await axios.get(`/api/v1.2/tireChalk/mobileReports`).then((res) => {setMobileReports(res.data); }).catch((error) => {});
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    let report = tireChalkReport;
    let items = report.streetStatuses;
    items = streets;
    report.streetStatuses = items;
    report.buildingLocation = currentLocation;

    if(!isAdmin){
      if(currentUser !== 'none' && currentUserPassword !== 'none'){
        report.trustedUserId = currentUser;
        report.trustedUserPassword = currentUserPassword;
        report.sessionUUID = localStorage.getItem('sessionUUID');
      }else{
        report.trustedUserId = null;
        report.trustedUserPassword = null;
        report.sessionUUID = localStorage.getItem('sessionUUID');
      }
    }

    setTireChalkReport(report);

    await axios.post("/api/v1.2/tireChalk/" + (isAdmin && isEditing ? "admin/" : '') + (isEditing ? "edit" : "create") + "TireChalkReports", 
      tireChalkReport,
      {
        headers: {
          'Authorization': (isAdmin ? `Bearer ${jwt}` :'')
        }
      }
      )
    .then( ()=> {
      setCommentsError("");
      setTimeError("");
    
      getTireChalkReports();
      clearForm(e);
      setShowChalkReportForm(false);

      if(isEditing){
        showBannerMessage('edit');
        setIsEditing(false);
      }else{
        showBannerMessage('create');
      }
    })
    .catch(function (error){    
      if(error.response.data.fieldErrors){
        setCommentsError("");
        setTimeError("");

        error.response.data.fieldErrors.forEach(fieldError => {
          if(fieldError.field === 'time'){
            setTimeError(fieldError.message);
          }
          if(fieldError.field === 'comments'){
            setCommentsError(fieldError.message);
          }
        });
      }else{
        clearForm(e);
        setShowChalkReportForm(false);
        showBannerMessage('unauthorized');
      }
    });
  }

  const getSessionCount = async() => {
    await axios.get(`/api/v1.2/tireChalk/sessionCount`)
    .then(
      (res) => {
       let counts = sessionCounts;
       let count = sessionCount;
       counts.hsSessionCount = res.data.hsSessionCount;
       counts.lsSessionCount = res.data.lsSessionCount;
       setSessionCounts(counts);
       count = currentLocation===1 ? sessionCounts.lsSessionCount : currentLocation === "1" ? sessionCounts.lsSessionCount : sessionCounts.hsSessionCount;
       setSessionCount(count);
      }).catch((error) => {});
  }

  const getParkingBan = async() => {
    await axios.get(`/api/v1.2/tireChalk/parkingBan`).then((res) => {setParkingBan(res.data)}).catch((error) => {});
  }

  const getTireChalkReports = async(currentLocation) => {
    generateSessionUUID();
    await axios.get(`/api/v1.2/tireChalk/tireChalkReports`, {
      
      params: {
        sessionUUID: localStorage.getItem('sessionUUID')
      }
    
    })
      .then(
        (res) => {
          let newReports = reports;
          newReports = res.data;
          setReports(newReports);
        }).catch((error) => {});
      await updateMobileReport();
      await getParkingBan();
  }

  const getTireChalkStreets = async() => {
    await axios.get(`/api/v1.2/tireChalk/streets`).then((res) => setStreetNames(res.data)).catch(
      (error) => {
        let emptyStreets = [];
        let count = 9;
        while(count !== 0){
          emptyStreets.push({
              id: count,
              buildingLocation: 0,
              streetName: " ... loading"
          });

          if(count < 9){
            emptyStreets.push({
              id: count,
              buildingLocation: 1,
              streetName: "... loading"
            });
          }
          
          count = count - 1;
        }
        
        let streets = streetNames;
        streets = emptyStreets;
        setStreetNames(streets);
      });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getTireChalkReports(currentLocation);

      let uuid = localStorage.getItem('sessionUUID');
      let sessionUpdate = {currentLocation: currentLocation, sessionUUID: uuid};
      axios.post(`/api/v1.2/tireChalk/sessionUpdate`, sessionUpdate).then(()=>{getSessionCount();});
    }, 20000);

    return () => clearInterval(interval);
  }, [currentLocation]);

  useEffect(() => {

    if(currentLocation === 0){
      setStreets(['uc','uc','uc','uc','uc','uc','uc','uc','uc']);
      document.title = 'I2C - Tire Chalk App';
    }else{
      setStreets(['uc','uc','uc','uc','uc','uc','uc','uc']);
      document.title = 'I2C - Tire Chalk App';
    }

    localStorage.setItem('currentLocation', currentLocation);

    let uuid = localStorage.getItem('sessionUUID');
    let sessionUpdate = {currentLocation: currentLocation, sessionUUID: uuid};
    axios.post(`/api/v1.2/tireChalk/sessionUpdate`, sessionUpdate).then(()=>{getSessionCount();});
    
  }, [currentLocation])


  

  // useEffect(() => {

  //   const sessionCountSse = new EventSource('/api/v1.2/tireChalk/sessionCount');

  //   sessionCountSse.onopen = () => {}

  //   sessionCountSse.addEventListener("tireChalkSessionCount", (event) => {
  //     console.log(event.data);
  //     setSessionCounts(JSON.parse(event.data));
  //     setSessionCount(currentLocation===1 ? sessionCounts.lsSessionCount : sessionCounts.hsSessionCount);
  //   });

  //   sessionCountSse.addEventListener("tireChalkSessionCountPing", (event) => {});

  //   sessionCountSse.onerror = (error) => {
  //     sessionCountSse.close();
  //   }

  //   return () => {
  //     sessionCountSse.close();
  //   };

  // }, [sessionCounts, sessionCount]);

  const check = async() => {
    let test  = await areNotificationsSupported();
    setNotificationsSupported(test);
    if(Notification.permission === "denied"){
      setNotificationsSupported(false);
    }else{
      onMessageListener().then(payload => {
        notifyUser(payload.data.title, payload.data.body, i2cIconLowRes);
      }).catch(err => console.log('failed: ', err));
    }

    return test;
  }

  useEffect(() => {

    check();

    const handleTabClose = event => {
      event.preventDefault();
      window.sessionStorage.removeItem("tireChalkVisitFlag");
    };

    const updateMediaMain = () => {
      if(window.innerWidth > 650){
        setIsVerySmallWindow(false);
      }
    };

    if(!window.sessionStorage.getItem("tireChalkVisitFlag")){
      window.sessionStorage.setItem('tireChalkVisitFlag', true);
    }

    getTireChalkStreets();
    trackPromise(getTireChalkReports(currentLocation));
    
    if(localStorage.getItem('trustedChalkUser') && localStorage.getItem('trustedChalkUserPassword')){
      setCurrentUser(localStorage.getItem('trustedChalkUser'));
      setCurrentUserPassword(localStorage.getItem('trustedChalkUserPassword'));
    }

    if(localStorage.getItem('currentLocation')){
      setCurrentLocation(parseInt(localStorage.getItem('currentLocation')));
    }

    window.addEventListener('unload', handleTabClose);
    window.addEventListener("resize", updateMediaMain);

    localStorage.setItem("hideAdminMessages", localStorage.getItem('hideAdminMessages'));

    return () => {
      window.removeEventListener('unload', handleTabClose)
      window.removeEventListener("resize", updateMediaMain)
    };
  }, []);

  const toggleAdminMessages = () => {
    let hideState = hideAdminMessages==='false' ? false : hideAdminMessages===false ? false : true; 
    setHideAdminMessages(!(hideState));
    localStorage.setItem('hideAdminMessages', !(hideState));
  }

  return (
    <div id='tireChalkPage'>
      <Columns multiline={false}>
        <Columns.Column size={6} offset={3}>
          <Hero color={'dark'} size={'small'}>
            <Hero.Body>
              <Container>
                <Heading weight={'bold'}>
                  Tire Chalk App 2.0 - {currentLocation === 0 ? "HS" : "LS"}
                </Heading>
              </Container>
            </Hero.Body>
          </Hero>
        </Columns.Column>
        <Columns.Column className='settingsButton' size={3} centered="true">
          {currentUser === 'none' && currentUserPassword === 'none' ?
            <FaDoorOpen title='Login' className='icon is-large settingsButtonIcon' onClick={(e) => {setShowLoginForm(!showLoginForm);}}/>
          :
            <FaDoorClosed 
              title='Logout' 
              color='#F14668'
              className='icon is-large settingsButtonIcon' 
              onClick={(e) => {
                setCurrentUser('none'); 
                setCurrentUserPassword('none'); 
                localStorage.removeItem('trustedChalkUser'); 
                localStorage.removeItem('trustedChalkUserPassword'); 
                getTireChalkReports();
              }}/>
          }
          <MdNotificationImportant title={hideAdminMessages==='false' ? "Hide Admin Messages" : hideAdminMessages===false ? "Hide Admin Messages" : 'View Admin Messages'} className='notificationIconButton icon is-large ' onClick={()=> {toggleAdminMessages();}}/>
        </Columns.Column>
      </Columns>
      <TrustedUserModal setActive={setShowLoginForm} active={showLoginForm ? true : false} setCurrentUser={setCurrentUser} setCurrentUserPassword={setCurrentUserPassword}/>
      { !showChalkReportForm ? 
      <>


      { (hideAdminMessages===false || hideAdminMessages==='false') &&
      <>
      {!isMobile && 
        <>
        <Columns multiline={false} centered="true">
          <Columns.Column size={10}>
            <Block>
              <Message color={'info'}>
                <Message.Header style={{display: "block"}}>
                  <Columns multiline={false}>
                    <Columns.Column >
                        Message/Request From Admin:
                    </Columns.Column>
                  </Columns>
                </Message.Header>
                <Message.Body size={10}> 
                  Thanks for all the feedback and recommendations! Please share the links for the Tire Chalk app to any indivual or on whichever system you would like - all are welcome!
                  The more who know about it the better the reporting / tracking.
                  <br/><br/>
                  <strong>Important: </strong>Please see the PDF file in the <a href='/communityProjects/project/view/233'>TireChalk App's Community Project space</a> for recent change notes and details on how the app functions.             
                </Message.Body>
              </Message>
            </Block>
          </Columns.Column>
        </Columns>
      </>
      }

      {notificationsSupported &&
      <>
      {!(Notification.permission === "granted") ? 
      <>
      <Columns multiline={false} centered="true">
        <Columns.Column size={10}>
          <Block>
            <Message color={'warning'}>
              <Message.Header style={{display: "block"}}>
                <Columns multiline={false}>
                  <Columns.Column >
                      Enable TireChalk Desktop Notifications?
                  </Columns.Column>
                  <Columns.Column className='dismissButton' size={3}>
                      <Button onClick={() => {enableNotifsAndClose()}}>Yes</Button>
                  </Columns.Column>
                </Columns>
              </Message.Header>
              <Message.Body size={10}> 
                Enable desktop notifications to allow the TireCalk App to alert you upon a Chalk or Ticket event. 
                This is helpful if you don't always check. It can be disabled at anytime with the the user interfaces buttons or changing your browser's permissions for the site!
              </Message.Body>
            </Message>
          </Block>
        </Columns.Column>
      </Columns>
      </>
      :
      <>
      {fcmClientToken === null && 
      <Columns multiline={false} centered="true">
        <Columns.Column size={10}>
          <Block>
            <Message color={'warning'}>
              <Message.Header style={{display: "block"}}>
                <Columns multiline={false}>
                  <Columns.Column >
                      {disabledNotifications ? "Do you want to re-enable TireChalk notifications?" : "Do you still want TireChalk notifications?"}
                  </Columns.Column>
                  <Columns.Column className='dismissButton' size={3}>
                      <Button onClick={()=> {enableNotifsAndClose()}}>Yes</Button>
                  </Columns.Column>
                </Columns>
              </Message.Header>
              <Message.Body size={10}> 
                {disabledNotifications ? 
                  "Looks like you disabled TireChalk notifications would you like to re-enable them? Your old settings should remain the same!" 
                : 
                  "Looks like you might've cleared you cache! We no longer have you notification subscription token. Would you like to generate a new one? You need this to recieve TireChalk notifications."
                }
              </Message.Body>
            </Message>
          </Block>
        </Columns.Column>
      </Columns>
      }
      </>
      }
      </>
      }
      </>
      }
    

      <Columns multiline={false} centered="true">
        {parkingBan.status !== 0 &&
          <Columns.Column size={10}>
            <Block>
              <Message color={parkingBan.status === 1 ? "warning" : parkingBan.status === 2 && "danger"}>
                <Message.Header>
                  <Columns multiline={false}>
                    <Columns.Column >
                        {parkingBan.status === 1 ? "Upcoming Parking Ban Detected!" : parkingBan.status === 2 && "Parking Ban was Detected!"}
                    </Columns.Column>
                  </Columns>
                </Message.Header>
                <Message.Body>
                  The TireChalk app has detected that a <strong>winter parking ban is {parkingBan.status === 1 ? "in upcoming" : parkingBan.status === 2 && "in effect"}!</strong> Park with caution / confirm <strong><a href='https://ottawa.ca/en/parking-roads-and-travel/parking/winter-parking' target="_blank">here</a></strong>.<br/>
                  Detection was made using <strong>Open311 GeoReport v2</strong> method and <strong>Ottawa.ca Page Scrape</strong> method.<br/><br/>
                  <strong>{parkingBan.message}</strong>
                </Message.Body>
              </Message>
            </Block>
          </Columns.Column> 
        }
      </Columns>

      <Columns multiline={false} centered="true" style={{padding: '0px'}}>
        {messageBanner &&
          <Columns.Column size={10} style={{padding: '0px'}}>
            <SuccessBanner message={bannerMessage} bannerColor={bannerColor}/>
          </Columns.Column> 
        }
      </Columns>

      <Spinner />

      {!promiseInProgress &&
      <div>

        {!isVerySmallWindow ? 
        
        <Columns multiline={false} centered="true">
          <Columns.Column size={10}>
            <TireChalkTable 
              streetNames={streetNames} 
              currentLocation={currentLocation} 
              reports={reports} 
              currentUser={currentUser}
              currentUserPassword={currentUserPassword}
              isAdmin={isAdmin}
              jwt={jwt}
              setIsVerySmallWindow={setIsVerySmallWindow} 
              setCurrentLocation={setCurrentLocation}
              setShowChalkReportForm={setShowChalkReportForm}
              setStreets={setStreets}
              setTireChalkReport={setTireChalkReport}
              setIsEditing={setIsEditing}
              getTireChalkReports={getTireChalkReports}
              getCurrentTime={getCurrentTime}
              showBannerMessage={showBannerMessage}
              parkingBan={parkingBan.status}
            />
          </Columns.Column>
        </Columns>

        :
        ( mobileReports[currentLocation] !== undefined &&
        <>
        <Columns multiline={false} centered="true">
          <Columns.Column size={10}>
            <Block>
              <Message color={'warning'}>
                <Message.Body size={10}> 
                  <strong>Please Note:</strong> <br/>
                  {!isVerySmallWindow || isMobile ? "This is the Mobile version" : "This is the 'You really like small browser windows' version" } of this page which keeps track of the most recent status (not the last) of the streets. 
                  It ignores entries with the 'Unchecked' status but will update a status to a lower severity if it was the most recently reported status. 
                  To view the full table you will have to view the page in {!isVerySmallWindow || isMobile ? "browser mode on your mobile device" : "a larger browser window"}.
                </Message.Body>
              </Message>
            </Block>
          </Columns.Column>
        </Columns>
        <Columns centered="true">
          <Columns.Column size={10}>
          <>
          <Card id='tireChalkMobileCard'>
            <Card.Header.Title className='tireChalkMobileTableHeader'>
              <Columns>
                <Columns.Column size={5}>
                  <div>
                    Street Statuses - {currentLocation === 0 ? "HS" : "LS"}:
                  </div>
                </Columns.Column>
                <Columns.Column size={5}>
                  <Columns>
                      <Button color='success' type='submit' onClick={() => {setShowChalkReportForm(!showChalkReportForm); getCurrentTime();}}><strong>New TireChalk Report</strong></Button>
                  </Columns>
                </Columns.Column>
                <Columns.Column size={2}>
                  <Form.Control>
                    <Form.Select name='changeCurrentLocation' value={currentLocation} onChange={(e) => {setCurrentLocation(parseInt(e.target.value));}}>
                      <option value="0">HS</option>
                      <option value="1">LS</option>
                    </Form.Select>
                  </Form.Control>
                </Columns.Column>
              </Columns>
            </Card.Header.Title>
            <Card.Content className='tireChalkMobileTableCard'>
              <Container>
                <div >
                <Table className='is-fullwidth'>
                  <thead>
                  <tr>
                    <th><abbr title="Name">Str. Name:</abbr></th>
                    <th><abbr title="Status">Status:</abbr></th>
                    <th><abbr title="Time">Time:</abbr></th>
                  </tr>
                  </thead>
                  <tbody>
                    {mobileReports[currentLocation].streets.length > 0 ?
                    streetNames.filter((s) => s.buildingLocation === currentLocation).map((street, i) => (
                      ((street.buildingLocation === currentLocation &&
                        <tr key={i}>
                          <td>{street.streetName}</td>
                          
                          <td className={mobileReports[currentLocation].streets[i] === 'uc' ? '' : mobileReports[currentLocation].streets[i] === 'nc' ? 'green' : mobileReports[currentLocation].streets[i] === 'ch' ? 'yellow' : mobileReports[currentLocation].streets[i] === 'ts' && 'red'}>
                            {mobileReports[currentLocation].streets[i] === 'uc' ? 'Unchecked' : mobileReports[currentLocation].streets[i] === 'nc' ? 'No Chalk' : mobileReports[currentLocation].streets[i] === 'ch' ? 'Chalk' : mobileReports[currentLocation].streets[i] === 'ts' && 'Tickets'}
                          </td>
                          <td>{mobileReports[currentLocation].times[i]}</td>

                        </tr>
                      )
                    )))
                    :
                    <tr>
                      <td colSpan={3} style={{border: 'none', textAlign: 'center'}}>No Content</td>
                    </tr>
                    }
                  </tbody>
                </Table>
                </div>
              </Container>
            </Card.Content>
          </Card>
          </>
          </Columns.Column>
        </Columns>
        </>
        )
        }


        {/* <Columns.Column size={12}>
          <Columns centered='true' style={{textAlign: 'center'}}>
              <Button color='success' type='submit' onClick={() => {setShowChalkReportForm(!showChalkReportForm); getCurrentTime();}}>New TireChalk Report</Button>
          </Columns>
        </Columns.Column> */}
        <Columns.Column size={12} style={{textAlign: 'center'}}>
          <Columns centered='true' id='sessionCountColumn'>
            <strong>{!isMobile && "Ball Park for "}{parseInt(localStorage.getItem('currentLocation')) === 0 ? "HS " : "LS "}Active Sessions : </strong><br/>{sessionCount === 0 ? <div className='sessionCountLoading'>... loading</div> : <strong className='sessionCount'>{sessionCount}</strong>}<strong className='sessionCount'>{!isMobile && sessionCount !== 0 && "¯\\_(ツ)_/¯"}</strong> 
          </Columns>
        </Columns.Column>
        {Notification.permission==='granted' && !disabledNotifications && disabledNotifications !== undefined &&
        <Columns multiline={false} centered="true" style={{textAlign: 'center'}}>
            <Columns.Column size={10} centered='true'>
              <Button color={'danger'} className='notificationButtons' onClick={(e) => {disableNotifsAndClose();}}>Disable Notifications</Button>
              {fcmClientLocation !== currentLocation &&
                <Button color={'warning'} className='notificationButtons' onClick={(e) => {setFcmClientLocation(currentLocation); toggleNotificationLocation();}}>Notify for {currentLocation===0 ? "HS" : "LS"} </Button>
              }
            </Columns.Column>
        </Columns>
        }
      </div>
      }
      </>
    :
      <Columns multiline={false} centered>
      <Columns.Column size={8}>
        <Card id='tireChalkReportForm'>
          <Card.Header.Title className='tireChalkMobileTableHeader'>
            <Columns>
              <Columns.Column size={10}>
                <div>
                  Tire Chalk Report Form: - {currentLocation === 0 ? "HS" : "LS"}:
                </div>
              </Columns.Column>
              <Columns.Column size={2}>
                {/* {!isEditing &&
                <Form.Control>
                  <Form.Select name='changeCurrentLocation' value={currentLocation} onChange={(e) => {setCurrentLocation(e.target.value === "0" ? 0 : e.target.value === "1" && 1); clearFormDuringReport();}}>
                    <option value="0">HS</option>
                    <option value="1">LS</option>
                  </Form.Select>
                </Form.Control>
                } */}
              </Columns.Column>
            </Columns>
          </Card.Header.Title>
          <Card.Content>
            <Content>
              <form onSubmit={handleSubmit}>
                <Form.Field>
                  <Form.Label><Icon align="left" size="small"><FaClock /></Icon> Time:</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type='time'
                      min="00:00" 
                      max="24:00" 
                      value={tireChalkReport.time}
                      onChange={(e) => {
                        setTireChalkReport({...tireChalkReport, time: e.target.value});
                      }}
                    />
                  </Form.Control>
                  { timeError && <span className='errorMessage'>{timeError}</span>}
                </Form.Field>
                <Form.Field>
                  <Form.Label><Icon align="left" size="small"><FaComments /></Icon> Comment:</Form.Label>
                  <Form.Control>
                    <Form.Textarea
                      value={tireChalkReport.comments}
                      onChange={(e) => {
                        setTireChalkReport({...tireChalkReport, comments: e.target.value});
                      }}
                    />
                  </Form.Control>
                  { commentsError && <span className='errorMessage'>{commentsError}</span>}
                </Form.Field>
                    <Form.Field>
                      <Form.Label><Icon align="left" size="small"><FaRoad /></Icon> Streets:</Form.Label>
                      <div className='streetStatus'>

                      {streetNames.filter((s) => s.buildingLocation === currentLocation).map((street, i) => (
                        
                        <Form.Control key={i}>
                          <Columns.Column size={12}>
                            <Columns>
                              <Columns.Column size={6}>
                                {street.streetName}
                              </Columns.Column>
                              <Columns.Column size={6}>
                                <TireChalkFormStreetSelect streetStatus={streets[i]} index={i} updateStreetStatus={updateStreetStatus}/>
                              </Columns.Column>
                            </Columns>
                          </Columns.Column>
                        </Form.Control>
                        
                      ))}

                      </div>
                    </Form.Field>
                
                <Form.Field >
                  <Button type='submit' color="success" onClick={(e) => {handleSubmit(e);}}>
                    {isEditing ? 'Change' : 'Submit'} 
                  </Button>
                  <Button color="danger" onClick={(e) => {clearForm(e); setShowChalkReportForm(!showChalkReportForm); {isEditing && setIsEditing(false);}}} >
                    Cancel
                  </Button>
                </Form.Field>
              </form>
            </Content>
          </Card.Content>
        </Card>
      </Columns.Column>
    </Columns>
    }

    
    

    </div>
  );
}

export default TireChalk