import React, { useState } from 'react'
import { Card,  Content, Button } from 'react-bulma-components'
import { useLocation, useNavigate } from 'react-router-dom'

import imageNotFound from '../../assets/image_not_found.png';

const ProjectCard = (props) => {

  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();
  const id = props.projectId;
  const location = useLocation();

  const projectClicked= () => {
    if(location.pathname.includes("itcProjects")){
      navigate('/itcProjects/project/view/' + id );
    }else{
      navigate('/communityProjects/project/view/' + id );
    }
  } 

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const cardStyle = {
    width: '90%',
    margin: 'auto',
    boxShadow: isHover ?"10px 10px 10px #f7be20" : "none",
    height: '100%',
  };

  return (
    <Card style={cardStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      
      <div onClick={ () => projectClicked() }>
        <Card.Image size="4by3" src={`/projects_images/${props.imageFile}`} alt="Project Card Image" fallback={imageNotFound}/>
        <Card.Content >
          <Content>
            <p className="title is-5">{props.projectTitle}</p>
            <p className="subtitle is-6">{props.projectOwner}</p>
          </Content>
        </Card.Content>
      </div>
    </Card>
  );
}

export default ProjectCard