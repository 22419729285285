import { create } from 'zustand';
import { persist } from 'zustand/middleware';

let tokenStore = (set) => ({
  jwt: "",
  setJwt: (token) =>
    set((state) => ({jwt: token})),
})

tokenStore = persist(tokenStore, {name: "user_token", });

const useTokenStore = create(tokenStore);

export default useTokenStore