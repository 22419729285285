import React, { useEffect, useState } from 'react';
import '../login/Login.css';

import axios from 'axios';
import { Form, Button, Icon, Card, Columns, Content } from 'react-bulma-components';
import { FaUser, FaKey } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import useTokenStore from '../../utils/store';


const Login = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const expiredLogin = location?.state?.expiredLogin || false;
  const setJwt = useTokenStore(state => state.setJwt);

  const [loginReq, setLoginReq] = useState({
    username: "",
    password: ""
  })

  const authenticate = async (login) => {
    await axios.post("/api/auth/login", login).then( (res) => {
      setJwt(res.headers.get('authorization'));
      navigate("/admin");
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    authenticate(loginReq);
  }

  useEffect(()=>{
    setJwt("");
  },[props.expiredLogin, props.expireLogin])

  return (
    <div id="loginPage">
      <Columns multiline={false} centered vCentered>
        <Columns.Column size={8}>
          <Card id='loginForm'>
            <Card.Header.Title>
              Admin Login:
            </Card.Header.Title>
            <Card.Content>
              <Content>
                <form onSubmit={handleSubmit}>
                  <Form.Field>
                    { expiredLogin ? <span style={{ color: 'red', fontSize: '12px'}}>Your login session expired please login again</span> : ""}
                    <Form.Label required>Username:</Form.Label>
                    <Form.Control>
                      <Form.Input
                        value={loginReq.username}
                        name="username"
                        onChange={(e) => {
                          return setLoginReq({...loginReq, [e.target.name]: e.target.value});
                        }}
                      />
                      <Icon align="left" size="small">
                        <FaUser />
                      </Icon>
                    </Form.Control>
                  </Form.Field>
                  <Form.Field>
                    <Form.Label required>Password:</Form.Label>
                    <Form.Control>
                      <Form.Input
                        value={loginReq.password}
                        name="password"
                        onChange={(e) => {
                          return setLoginReq({...loginReq, [e.target.name]: e.target.value});
                        }}
                      />
                      <Icon align="left" size="small">
                        <FaKey />
                      </Icon>
                    </Form.Control>
                  </Form.Field>
                  <Form.Field >
                  <Button type='submit' color="success">
                    Login
                  </Button>
                </Form.Field>
                </form>
              </Content>
            </Card.Content>
          </Card>
        </Columns.Column>
      </Columns>
    </div>
  )
}

export default Login