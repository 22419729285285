import React, { useEffect, useState } from 'react';
import '../questions/Questions.css'

import { Columns, Panel } from 'react-bulma-components'
import ControlledSwitch from 'react-bulma-switch';

import { FaEdit, FaTrash } from 'react-icons/fa';
import { RequiredAuthRoles } from '../../utils/util';


const Questions = (props) => {

  const [q, setQ] = useState([]);

  const toggleActive = (q) => {
    props.updateActiveState({id: q.id, title: q.title, answer: q.answer, active: !(q.active), type: q.type});
  }

  const deleteFaq = (id) => {
    props.deleteFaq(id);
  }

  const editFaq = (q) => {
    props.editFaq({id: q.id, title: q.title, answer: q.answer, active: q.active, type: q.type});
  }
  
  useEffect(()=>{
    setQ([...props.questions]);
  },[props.questions])

  return (
    <div>
    {props.isAdmin ? 
      q.map((question) => ( 
        <Columns centered="true" multiline={false} key={question.id}>
          <Columns.Column size={9} >
            <Panel color={'light'}>
              <Panel.Header>
                <RequiredAuthRoles allowedRoles={["ROLE_80085"]}>
                  <div className='questionHeader'>
                    <span>
                      <div className='questionButtons'>
                        <ControlledSwitch value={question.active} thin={true} onChange={(e) => {toggleActive(question);}}/>
                      </div>
                      <div className='questionButtons'>
                        <FaEdit className='questionEditButtonIcon' size={20} onClick={(e) => {editFaq(question);}}/>
                      </div>
                      <div>
                        <FaTrash className='questionDeleteButtonIcon' size={19} onClick={(e) => {deleteFaq(question.id);}}/>
                      </div>
                    </span>
                  </div>
                </RequiredAuthRoles>
                {question.title} 
              </Panel.Header>
              <Panel.Block>
                {question.answer}
              </Panel.Block>
            </Panel>
          </Columns.Column>
        </Columns>
      ))
    :
      q.map((question) => ( 
        question.active && question.type === props.showType &&
          <Columns centered="true" multiline={false} key={question.id}>
            <Columns.Column size={9} >
              <Panel color={'light'}>
                <Panel.Header>
                  {question.title} 
                </Panel.Header>
                <Panel.Block>
                  {question.answer}
                </Panel.Block>
              </Panel>
            </Columns.Column>
          </Columns>
      ))
    }
    </div>
  );
}

export default Questions