import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Columns, Card, Button } from 'react-bulma-components';

import accessDenied from '../../assets/access_denied.png';
import '../accessDenied/AccessDenied.css';

function AccessDenied() {

  const navigate = useNavigate();

  return (
    <div id='accessDenied'>
      <Columns centered="true" vcentered="true" multiline={false}>
        <Columns.Column size={10} >
          <Card>
            <div>
              <Card.Image src={accessDenied}/>
            </div>
            <Button onClick={(e) => {navigate(`/home`)}}><b>Go Home</b></Button>
          </Card>
        </Columns.Column>
      </Columns>
    </div>
  )
}

export default AccessDenied