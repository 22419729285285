import React  from 'react';
import { Card,  Content } from 'react-bulma-components';
import { FaEdit, FaTrash } from 'react-icons/fa';
import '../pendingProjectCard/PendingProjectCard.css';
import pendingProjectImg from '../../assets/pending_project.png';
import pendingComingSoonProjectImg from '../../assets/pending_project_coming_soon.png';

import { useLocation } from 'react-router-dom';
import { RequiredAuthRoles } from '../../utils/util';

const PendingProjectCard = (props) => {

  const projectId = props.projectId;
  const location = useLocation();
  
  const activeModal = (type) => {
    props.setId(projectId);
    props.setActive(true);
    props.setCheckType(type);
    props.setPendingProject(true);
  }

  const cardStyle = {
    width: '90%',
    margin: 'auto',
    height: '100%',
  };

  return (
    
    <>
    <Card className='pendingCard' style={cardStyle}>
      <div id="buttonContainer">
        {location.pathname.includes("itcProjects") ? 
          <>
          <RequiredAuthRoles allowedRoles={["ROLE_80085"]}>
            <FaEdit id='pendingCardEditButton' size={22} onClick={() => {activeModal("edit");}}/>
            <FaTrash id='pendingCardDeleteButton' size={19} onClick={() => {activeModal("delete");}}/>
          </RequiredAuthRoles>
          </>
        :
          <>
          <FaEdit id='pendingCardEditButton' size={22} onClick={() => {activeModal("edit");}}/>
          <FaTrash id='pendingCardDeleteButton' size={19} onClick={() => {activeModal("delete");}}/>
          </>
        }
      </div>
      <Card.Image size="4by3" src={location.pathname.includes("itcProjects") ? pendingComingSoonProjectImg : pendingProjectImg} alt="Pending Project Card Image" />
      <Card.Content>
        <Content>
          <p className="title is-5">Coming Soon </p>
          <p className="subtitle is-6">Project ID: {projectId}</p>
        </Content>
      </Card.Content>
    </Card>
    </>
  );
}

export default PendingProjectCard