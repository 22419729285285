import React, {useState, useEffect} from 'react'

import { Form } from 'react-bulma-components';

const TireChalkFormStreetSelect = (props) => {

  const [streetStatus, setStreetStatus] = useState(props.streetStatus);

  useEffect(() =>{
    props.updateStreetStatus(props.index, streetStatus);
  },[streetStatus]);

  return (
    <Form.Select value={streetStatus} onChange={(e) => {setStreetStatus(e.target.value)}}>
      <option value="uc">Unchecked</option>
      <option value="nc">No Chalk</option>
      <option value="ch">Chalked</option>
      <option value="ts">Tickets</option>
    </Form.Select>
  )
}

export default TireChalkFormStreetSelect