import React, { useState, useEffect, } from 'react';
import '../viewProject/ViewProject.css';
import axios from 'axios';

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Columns, Content, Card, Button} from 'react-bulma-components';

import { GiMushroomCloud } from 'react-icons/gi';
import { FaEdit, FaCartArrowDown, FaExternalLinkAlt } from 'react-icons/fa';
import { ImFolderDownload } from 'react-icons/im';
import { GrDocumentPdf } from 'react-icons/gr';
import { isMobile } from 'react-device-detect';

import ChangeModal from '../../components/changeModal/ChangeModal';
import ViewPdf from '../viewPdf/ViewPdf';
import Spinner from '../../components/loadingSpinner/Spinner';
import WaitingModal from '../../components/waitingModal/WaitingModal';

import imageNotFound from '../../assets/image_not_found.png';
import { RequiredAuthRoles } from '../../utils/util';
import SuccessBanner from '../../components/successBanner/SuccessBanner';

const ViewProject = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { promiseInProgress } = usePromiseTracker();

  const [togglePdfView, setTogglePdfView] = useState(false);
  const [ddlOptions, setDdlOptions] = useState('');
  const [downloadable, setDownloadable] = useState(false);
  const [active, setActive] = useState(false);
  const [checkType, setCheckType] = useState('');
  const [processing, setProcessing] = useState(false);

  const [bannerMessage, setBannerMessage] = useState(location?.state?.bannerMessage ? location.state.bannerMessage : "");
  const [bannerColor, setBannerColor] = useState(location?.state?.bannerColor ? location.state.bannerColor : "success");

  const [project, setProject] = useState({
    title: "",
    description: "",
    name: "",
    email: "",
    price: "",
    priceRange: [],
    pdfPath: "",
    imagePath: "",
    stlDownloadLink: "",
    status: "",
    isOrderable: false,
    isOpenSource: false,
    approved: null,
    downloadZipFilePath: ""
  });

  useEffect(() => {
    trackPromise(loadProject());
    loadOptions();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setBannerMessage("");
      }, 7000);
      setBannerColor("");
  },[bannerMessage]);

  const loadProject = async () => {
    const result = await axios.get(`/api/projects/view/${id}`).catch(function (error) {navigate('/notFound');});
    if(result?.data?.approved === false){
      if(location.pathname.includes("itcProjects")){
        navigate("/itcProjects", {state: {bannerMessage: "That content is not available yet ... coming soon ... maybe ... also stop that !!!", bannerColor: "danger"}});
      }else{
        navigate("/communityProjects", {state: {bannerMessage: "That content is not available yet ... coming soon ... maybe ... also stop that !!!", bannerColor: "danger"}});
      }
    }
    setProject(result.data);
    if(result.data.stlDownloadLink !== ''){
      setDownloadable(true);
    }
  };

  const loadOptions = async () => {
    const result = await axios.get(`/api/projects/options/${id}`);
    setDdlOptions(result.data);
  };

  const moreDetailsClicked = () => {
    setTogglePdfView(!togglePdfView);
  }

  const orderProject = (e) => {
    if(location.pathname.includes("itcProjects")){
      if(project.price !== 9999){
        navigate(`/itcProjects/order/${id}`, {state: {projectTitle: project.title, options: ddlOptions, projectPrice: project.price}});
      } else {
        navigate(`/itcProjects/order/${id}`, {state: {projectTitle: project.title, options: ddlOptions}});
      }
    }else{
      if(project.price !== 9999){
        navigate(`/itcProjects/order/${id}`, {state: {projectTitle: project.title, options: ddlOptions, projectPrice: project.price}});
      } else {
        navigate(`/communityProjects/order/${id}`, {state: {projectTitle: project.title, options: ddlOptions}});
      }
    }
  }

  const activeModal = () => {
    setActive(true);
  }

  const editProject = (id, editCode, valid) => {
    if(valid){
      if(location.pathname.includes("itcProjects")){
        navigate(`/itcProjects/edit/project/${id}`, {state: {editCode: editCode, valid: valid}});
      }else{
        navigate(`/communityProjects/edit/project/${id}`, {state: {editCode: editCode, valid: valid}});
      }
    }
    setActive(false);
  }

  const deleteProject = async (id, editCode, valid) => {
    if(valid){
    setProcessing(true);
    await axios.delete(`/api/projects/delete/${id}`, {data:editCode})
              .then(()=>{
                if(location.pathname.includes("itcProjects")){
                  navigate("/itcProjects", {state: {bannerMessage: "The project was successfully nuked. We are sorry to see the project leave the platform."}});
                }else{
                  navigate("/communityProjects", {state: {bannerMessage: "The project was successfully nuked. We are sorry to see the project leave the platform."}});
                }
              })
              .catch(error => {console.log(error);});
    }
    setActive(false);
    setProcessing(false);
  }

  const getBackClickedState = (backClicked) => {
    backClicked ? setTogglePdfView(false) : setTogglePdfView(true);
  }

  const cardStyle = {
    margin: 'auto',
  };

  const downloadProjectFiles = (projectName) => {
      const fileUrl = `/projects_files/${projectName}_opensource_files.zip`;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `${projectName}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  };

  return (

    <div id='viewProjectPage' >
    <WaitingModal active={processing} message="delete"/>
    { bannerMessage!== "" ? <Columns centered={true}><Columns.Column size={8} ><SuccessBanner message={bannerMessage} bannerColor={bannerColor}/></Columns.Column></Columns> : <></> }
    { promiseInProgress ?
      <Spinner />
    :
      <>
      {project.approved &&
      <>
      {togglePdfView ?
      <ViewPdf projectFile={project.pdfPath} func={getBackClickedState}/>
      :
      <>
      { checkType === "edit" ?
      <ChangeModal setActive={setActive} active={active ? true : false} id={id} action={editProject}/>
      :
      <ChangeModal setActive={setActive} active={active ? true : false} id={id} action={deleteProject}/>
      }
      <Columns centered="true" multiline={false} vCentered="true">
        <Columns.Column size={10} id='viewProjectColumn'>
          <Card style={cardStyle}>
            <Card.Header>
              <Card.Header.Title>
                {project.title}
              </Card.Header.Title>
              <Card.Header.Icon>
                {downloadable ? <span><a href={project.stlDownloadLink}><FaExternalLinkAlt size={33} id='download'/></a></span> : '' }
                {project.downloadZipFilePath &&
                  <span><ImFolderDownload size={35} id='download' title='Download Project Zip' onClick={(e) => {downloadProjectFiles(project.title.replace(/\s+/g, '-'), project.downloadZipFilePath);}}/></span>
                }
                {location.pathname.includes("itcProjects") ?
                  <>
                  <RequiredAuthRoles allowedRoles={["ROLE_80085"]}>
                    <span><FaEdit onClick={(e) => {activeModal(); setCheckType("edit");}} size={35} id='edit'/></span>
                    <span><GiMushroomCloud onClick={(e) => {activeModal(); setCheckType("delete");}} size={35} id='delete'/></span>
                  </RequiredAuthRoles>
                  </>
                :
                  <>
                  <span><FaEdit onClick={(e) => {activeModal(); setCheckType("edit");}} size={35} id='edit'/></span>
                  <span><GiMushroomCloud onClick={(e) => {activeModal(); setCheckType("delete");}} size={35} id='delete'/></span>
                  </>
                }
              </Card.Header.Icon>
            </Card.Header>
            <Card.Content>
              <div id='projectDetails'>
                <Content>
                  <div className='columns'>
                    <Columns vCentered>
                        <Columns.Column size={6} >
                          <Card.Image size="4by3" alt="Project Card Image" style={cardStyle} src={`/projects_images/${project.imagePath}`} fallback={imageNotFound}/>
                        </Columns.Column>
                        <Columns.Column size={6}>
                          {project.isOpenSource ? <><span className='free'>OPEN-SOURCE PROJECT</span><br/><br/></> : ""}
                          <span className="title is-6">Project Owner: </span>{project.name}<br/><br/>
                          <span className="title is-6">Contact Email: </span><a href={"mailto:"+project.email}>{project.email}</a><br/><br/>
                          {project.price === 9999 ? 
                            <><span className="title is-6">Price: </span>{"$"+ project.priceRange[0] + " - " + "$" + project.priceRange[1]}<br/><br/></>
                          :
                            <><span className="title is-6">Price: </span>{project.price===0 ? <span> FREE</span> : "$"+ project.price}<br/><br/></>
                          }
                          <span className="title is-6">Project Status: </span>
                            {project.status===0 && <span><strong className='statusCompleted'>COMPLETED / NO CHANGES</strong></span>}
                            {project.status===1 && <span><strong className='statusTesting'>TESTING / MINIMAL CHANGES</strong></span>}
                            {project.status===2 && <span><strong className='statusActive'>ACTIVE DEVELOPMENT / CHANGES EXPECTED</strong></span>}
                            {project.status===3 && <span><strong className='statusDepricated'>DEPRECATED / OLD </strong></span>}
                            <br/><br/>
                          <span className="title is-6">Brief Description: </span><br/><div className='descriptionBox' dangerouslySetInnerHTML={{ __html: project.description }} /><br/>
                          {downloadable ? 
                          <>
                          <span className="title is-6">Project's External Link: </span><br/><span><a href={project.stlDownloadLink}>{ isMobile ? "Click Here"  : project.stlDownloadLink}</a></span>
                          </>
                          : ''}
                        </Columns.Column>
                        </Columns>
                    </div>
                </Content>
              </div>
            </Card.Content>
              <footer className="card-footer">
                { project.pdfPath === "" ? "" :<Button className='card-footer-item' onClick={(e) => moreDetailsClicked()}><GrDocumentPdf className='pdfFooterIcon'/>{isMobile ? "" : "More Details"}</Button>}
                { project.isOrderable ? <Button className='card-footer-item' onClick={(e) => orderProject(e)}><FaCartArrowDown color='black'className='cartFooterIcon'/>{isMobile ? "" : "Order"}</Button> : "" }
              </footer>
          </Card>
        </Columns.Column>
      </Columns>
      </>
      }
      </>
      }
      </>
      }
    </div>
  )
}

export default ViewProject