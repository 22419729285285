import React, { useState, useEffect } from 'react'
import './App.css';
import 'bulma/css/bulma.min.css';

import { Route, Routes } from 'react-router-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { isMobile } from 'react-device-detect';

import Sidebar from './components/sidebar/Sidebar';
import Home from './pages/home/Home';
import ItcProjects from './pages/itcProjects/ItcProjects';
import CommunityProjects from './pages/communityProjects/CommunityProjects';
import Questions from './pages/faq/Faq';
import ContactUs from './pages/contactUs/ContactUs';
import CreateProject from './pages/createProject/CreateProject';
import ViewProject from './pages/viewProject/ViewProject';
import AccessDenied from './pages/accessDenied/AccessDenied';
import OrderProject from './pages/orderProject/OrderProject';
import PageNotFound from './pages/pageNotFound/PageNotFound';
import EditProject from './pages/editProject/EditProject';
import Login from './pages/login/Login';
import Logout from './pages/logout/Logout';
import Admin from './pages/admin/Admin';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import useTokenStore from './utils/store';
import useVerifyToken from './utils/util';
import ViewProjectAdmin from './pages/admin/ViewProjectAdmin';
import TireChalk from './pages/tireChalk/TireChalk';
import EditPendingProject from './pages/editProject/EditPendingProject';

function App() {

  const jwt = useTokenStore(state => state.jwt);
  const validToken = useVerifyToken();
  const [isSidebarSmall, setIsSidebarSmall] = useState(true);

  const getSidebarState = (minimize) => {
    minimize ? setIsSidebarSmall(false) : setIsSidebarSmall(true);
  }

  useEffect(() => {
    if(isMobile){
      window.addEventListener("load",function() {
        setTimeout(function(){
            window.scrollTo(0, 1);
        }, 0);
    });
    }
  },[])

  return (
    <>
      <div className="App">
        <ProSidebarProvider>
        <div className={"sideNav " + (isSidebarSmall ? "" : "sidebarCollapsedContentArea")}>
            <Sidebar func={getSidebarState} evaluateRoles={validToken}/> 
        </div>
        <div className={"contentArea " + (isSidebarSmall ? "sidebarIn" : "sidebarCollapsedContentArea") }>
          <Routes>
            <Route exact path="/" element={<Home sidebarCollapsed={isSidebarSmall} />}></Route>
            <Route path="/home" element={<Home sidebarCollapsed={isSidebarSmall} />}></Route>
            <Route path="/itcProjects" element={<ItcProjects /> }></Route>
            <Route path="/communityProjects" element={<CommunityProjects /> }></Route>
            <Route path="/faq" element={<Questions /> }></Route>
            <Route path="/contactUs" element={<ContactUs /> }></Route>
            <Route path="/create/project" element={<CreateProject />}></Route>

            <Route path="/itcProjects/edit/project/:id" element={<EditProject />}></Route>
            <Route path="/communityProjects/edit/project/:id" element={<EditProject />}></Route>

            <Route path="/itcProjects/edit/pending/project/:id" element={<EditPendingProject />}></Route>
            <Route path="communityProjects/edit/pending/project/:id" element={<EditPendingProject />}></Route>

            <Route path="/itcProjects/project/view/:id" element={<ViewProject />}></Route>
            <Route path="/communityProjects/project/view/:id" element={<ViewProject />}></Route>

            <Route path="/itcProjects/order/:id" element={<OrderProject />}></Route>
            <Route path="/communityProjects/order/:id" element={<OrderProject />}></Route> 
            <Route path="/tireChalk" element={<TireChalk/>}></Route>
            
            <Route path="/denied" element={<AccessDenied />}></Route>
            <Route path="/notFound" element={<PageNotFound />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/logout" element={<Logout />}></Route>
            
            <Route element={<ProtectedRoute allowedRoles={["ROLE_80085"]}/>}>
              <Route path="/admin" element={<Admin/>}/>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={["ROLE_80085"]}/>}>
              <Route path="/admin/projects/:id" element={<ViewProjectAdmin/>}/>
            </Route>

            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
        </div>
        </ProSidebarProvider>
      </div>
    </>
  );
}


export default App;
