import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Columns, Card, Button } from 'react-bulma-components';

import linkNotFound from '../../assets/link_not_found.png';
import '../pageNotFound/PageNotFound.css';
import { isFirefox } from 'react-device-detect';

function PageNotFound() {

  const navigate = useNavigate();

  return (
    <div id='pageNotFound' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
      <Columns centered="true" vcentered="true" multiline={false}>
        <Columns.Column size={10} >
          <Card>
            <div>
              <Card.Image alt="Link Not Found Image" src={linkNotFound} className={isFirefox ? "ff" :"notFF"}/>
            </div>
            <Button onClick={(e) => {navigate(`/home`)}}><b>Go Home</b></Button>
          </Card>
        </Columns.Column>
      </Columns>
    </div>
  )
}

export default PageNotFound