import jwt_decode from 'jwt-decode';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useTokenStore from '../../utils/store';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const jwt = useTokenStore((state) => state.jwt);

  const protect = ( 
   jwt ? jwt_decode(jwt).exp * 1000 < Date.now() ? (<Navigate to="/login" state={{expiredLogin: true}}/>) : (allowedRoles).every(role => (jwt_decode(jwt).roles).includes(role)) ? <Outlet/> : (<Navigate to="/login" />) : (<Navigate to="/login" />) 
  )

  return protect;
}

export default ProtectedRoute