import React, { useState, useEffect } from 'react';
import {  useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../orderProject/OrderProject.css';

import { FaUser } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'

import { Form, Button, Icon, Card, Columns, Hero, Container, Heading, Content, Block, Message } from 'react-bulma-components';

const OrderProject = () => {

  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = useState(location?.state?.projectTitle ? location.state.projectTitle : "");
  const price = useState(location?.state?.projectPrice ? location.state.projectPrice : 0);

  const [disabled, setDisabled] = useState(false);

  const [errorName, setErrorName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorType, setErrorType] = useState('');
  const [errorBuilding, setErrorBuilding] = useState('');
  const [errorQuantity, setErrorQuantity] = useState('');
  const [options, setOptions] = useState(location?.state?.options ? location.state.options : null);

  const [order, setOrder] = useState({
    projectId: "",
    name: "",
    email: "",
    message: "",
    type: "5",
    quantity: "0",
    price: 0,
    building: location.pathname.includes("itcProjects") ? 5 : 0
  })

  const onInputChange = async (e) => {
    setDisabled(false);
    setOrder({...order, [e.target.name]: e.target.value});
  }

  const validOptionFormat = (option) => {
    if(/^[A-Za-z0-9 \-&_]+ - \$(\d+)(\.[0-9][0-9]?)?$/gm.test(options[option])){
      return true;
    }
    return false;
  }

  const calculateOptionsPrice = () => {
    const optionCost = options[order.type] + "";
    const cost = optionCost.split('$')[1] * order.quantity
    setOrder({...order, price: cost});
    return cost;
  }

  const calculatePrice = () => {
    if(validOptionFormat(order.type)){
      return calculateOptionsPrice();
    }else{
      const cost = price[0] * order.quantity;
      setOrder({...order, price: cost});
      return cost;
    } 
  }

  useEffect(() => {
    const check = axios.get(`/api/projects/view/${id}`).catch(function (error) {navigate('/notFound');});
  }, []);

  useEffect(() => {
    if(Array.from(options).length === 0){
      order.type=-1;
    }
  },[options]);

  useEffect(() => {
    calculatePrice();
  },[order.quantity, order.type]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    axios.post(`/api/projects/order/${id}`, order)
    .then( ()=> {
      if(location.pathname.includes("itcProjects")){
        navigate(`/itcProjects/project/view/${id}`, {state: {bannerMessage: "Your Order has been sent.", bannerColor: "success"}})
      }else{
        navigate(`/communityProjects/project/view/${id}`, {state: {bannerMessage: "Your Order has been sent.", bannerColor: "success"}})
      }
      
    })
    .catch(function (error){    

      if(error.response.data.fieldErrors){
        setErrorName("")
        setErrorEmail("")
        setErrorMessage("")
        setErrorQuantity("")
        setErrorType("")
        setErrorBuilding("")

        error.response.data.fieldErrors.forEach(fieldError => {
          if(fieldError.field === 'name'){
            setErrorName(fieldError.message);
          }
          if(fieldError.field === 'email'){
            setErrorEmail(fieldError.message);
          }
          if(fieldError.field === 'message'){
            setErrorMessage(fieldError.message);
          }
          if(fieldError.field === 'quantity'){
            setErrorQuantity(fieldError.message);
          }
          if(fieldError.field === 'type'){
            setErrorType(fieldError.message);
          }
          if(fieldError.field === 'building'){
            setErrorBuilding(fieldError.message);
          }
        });
      }
    })
  }

  return (
    <div id='orderPage'>
    { title[0] !== "" ?
    <>
    <Columns multiline={false}>
        <Columns.Column size={6} offset={3}>
          <Hero color={'dark'} size={'small'}>
            <Hero.Body>
              <Container>
                <Heading weight={'bold'}>
                  I Wants ...
                </Heading>
                <Heading subtitle>
                  {title}
                </Heading>
              </Container>
            </Hero.Body>
          </Hero>
        </Columns.Column>
    </Columns>
    <Columns multiline={false} centered="true">
      <Columns.Column size={8}>
        <Block>
          <Message color={'warning'}>
            <Message.Body size={8}> 
              <strong>Please Note:</strong> Please ensure you monitor the email you provide. Workplace systems should not be used to reach the project owner. This is explained in the <a href='/faq'>FAQ</a> section.
            </Message.Body>
          </Message>
        </Block>
        <Card>
          <Card.Header.Title>
            Order Form:
          </Card.Header.Title>
          <Card.Content>
            <Content>
              <form onSubmit={handleSubmit}>
                <Form.Field>
                  <Form.Label required>Name:</Form.Label>
                  <Form.Control>
                    <Form.Input
                      name='name'
                      value={order.name}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                    <Icon align="left" size="small">
                      <FaUser />
                    </Icon>
                  </Form.Control>
                  { errorName ? <span style={{ color: 'red', fontSize: '12px'}}>{errorName}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Label>Email:</Form.Label>
                  <Form.Control>
                    <Form.Input
                      name='email'
                      value={order.email}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                    <Icon align="left" size="small">
                      <MdEmail />
                    </Icon>
                  </Form.Control>
                  { errorEmail ? <span style={{ color: 'red', fontSize: '12px'}}>{errorEmail}</span> : ""}
                </Form.Field> 
                { Array.from(options).length !== 0 &&
                <Form.Field>
                  <Form.Label required>Project Option:</Form.Label>
                  <Form.Control>
                    <Form.Select
                        name='type'
                        value={order.type}
                        onChange={(e) => {
                          onInputChange(e);
                        }}
                      >
                      <option  value='5'>Please Select One</option>
                      { options ? 
                        options.map((option, index) => (
                          <option key={index} value={index}>{option}</option>
                        )) 
                      : ''
                      }
                    </Form.Select>
                  </Form.Control>
                  { errorType ? <span style={{ color: 'red', fontSize: '12px'}}>{errorType}</span> : ""}
                </Form.Field>
                }
                <Form.Field>
                  <Form.Label>Quantity:</Form.Label>
                  <Form.Control>
                    <Form.Input
                      name='quantity'
                      value={order.quantity}
                      type='number'
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                  </Form.Control>
                  { errorQuantity ? <span style={{ color: 'red', fontSize: '12px'}}>{errorQuantity}</span> : ""}
                </Form.Field>
                {Array.from(options).length > 0 ? order.price === 0 ?
                  <></>
                : 
                  <Form.Label>Expected Price: <span style={{ color: 'red'}}>{order.price === 0 ? "FREE" : "$"+order.price}</span></Form.Label> 
                : order.price > 0 && 
                  <Form.Label>Expected Price: <span style={{ color: 'red'}}>{"$"+order.price}</span></Form.Label> 
                }
                <Form.Field>
                  <Form.Label>Message:</Form.Label>
                  <Form.Textarea
                    name='message'
                    value={order.message}
                    onChange={(e) => {
                      onInputChange(e);
                    }}
                  />
                  { errorMessage ? <span style={{ color: 'red', fontSize: '12px'}}>{errorMessage}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Label required>Pickup Location:</Form.Label>
                  {location.pathname.includes("itcProjects") && order.building === "0" ? 
                  <Message color={'warning'} style={{marginBottom: "15px"}}>
                    <Message.Body size={8}> 
                    <strong>IMPORTANT:</strong> The HS pickup location is between the angel gates and the turnstiles by the phone lockers. Usually near the 'Assisted Walking' sign by the window.
                    </Message.Body>
                  </Message>
                  : order.building === "1" &&
                  <Message color={'warning'} style={{marginBottom: "15px"}}>
                    <Message.Body size={8}> 
                      <strong>IMPORTANT:</strong> The LS pickup location is at the 1st floor kitchenette with the coffee / vending machines.
                    </Message.Body>
                  </Message>
                  }
                  <Form.Control>
                    <Form.Select
                        name='building'
                        value={order.building}
                        onChange={(e) => {
                          onInputChange(e);
                        }}
                      >
                      <option defaultChecked='true' value='5'>Please Select One</option>
                      <option value='1'>LS - Vanier Building</option>
                      <option value='0'>HS - Main Building</option>
                    </Form.Select>
                  </Form.Control>
                  { errorBuilding ? <span style={{ color: 'red', fontSize: '12px'}}>{errorBuilding}</span> : ""}
                </Form.Field>
                <Form.Field >
                  <Button type='submit' color="success" disabled={disabled}>
                    Order
                  </Button>
                  <Button color="danger" onClick={(e) => { location.pathname.includes("itcProjects") ? navigate(`/itcProjects/project/view/${id}`) : navigate(`/communityProjects/project/view/${id}`)}} >
                    Cancel
                  </Button>
                </Form.Field>
              </form>
            </Content>
          </Card.Content>
        </Card>
      </Columns.Column>
    </Columns>
    </>
    :
    <>
    <Columns multiline={false} centered="true">
        <Columns.Column size={8}>
          <Block>
            <Message color={'danger'}>
              <Message.Body><strong>That content is not available yet <br/> ... coming soon <br/> ... maybe <br/> ... also stop that !!!</strong></Message.Body>
            </Message>
          </Block>
        </Columns.Column>
    </Columns>
    <Columns multiline={false} centered="true">
      <Columns.Column id='notAvailableButtonColumn' size={8}>
        <Button onClick={(e) => { location.pathname.includes("itcProjects") ? navigate("/itcProjects") : navigate("/communityProjects");}}>Go Back</Button>
      </Columns.Column>
    </Columns>
    </>
    }
    </div>
  )
}

export default OrderProject