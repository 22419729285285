import React, { useState } from 'react';
import axios from 'axios';
import '../contactUs/ContactUs.css';

import { FaUser } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Form, Button, Icon, Card, Columns, Hero, Container, Heading, Content, Block, Message } from 'react-bulma-components';

import SuccessBanner from '../../components/successBanner/SuccessBanner';

const ContactUs = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('select-one');
  const [message, setMessage] = useState('');
  const [messageBanner, setMessageBanner] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [errorName, setErrorName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorType, setErrorType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    const createEmail = {
      name,
      email,
      type,
      message
    }

    axios.post("/api/contactUs", createEmail)
      .then( ()=> {
        
        clearForm(e);
        setErrorName("");
        setErrorEmail("");
        setErrorMessage("");
        setErrorType("");
        setMessageBanner(true);
        setTimeout(() => {
          setMessageBanner(false);
          }, 7000);
      })
      .catch(function (error){    
        if(error.response.data.fieldErrors){
          setErrorName("")
          setErrorEmail("")
          setErrorMessage("")
          setErrorType("")

          error.response.data.fieldErrors.forEach(fieldError => {
            if(fieldError.field === 'name'){
              setErrorName(fieldError.message);
            }
            if(fieldError.field === 'email'){
              setErrorEmail(fieldError.message);
            }
            if(fieldError.field === 'message'){
              setErrorMessage(fieldError.message);
            }
            if(fieldError.field === 'type'){
              setErrorType(fieldError.message);
            }
          });
        }
      })
  }

  const clearForm = (e) => {
    e.preventDefault();
    setDisabled(false);
    setName('');
    setEmail('');
    setType('select-one');
    setMessage('');
  }

  return (
    <div id='contactUsPage'>
    <Columns multiline={false}>
        <Columns.Column size={6} offset={3}>
          <Hero color={'dark'} size={'small'}>
            <Hero.Body>
              <Container>
                <Heading weight={'bold'}>
                  Contact Us
                </Heading>
              </Container>
            </Hero.Body>
          </Hero>
        </Columns.Column>
    </Columns>
    {messageBanner ? <Columns.Column size={8}><SuccessBanner message={"Email Sent"}/></Columns.Column> : <></>}
    <Columns multiline={false} centered="true">
        <Columns.Column size={8}>
          <Block>
            <Message color={'danger'}>
              <Message.Body size={8}> 
              <strong>Please Note:</strong> <br/> Before reaching out please see the <strong><a href='/faq'>FAQ</a></strong> section of the I2C Platform. There is a very good chance most questions and concerns will be addressed there. Please keep responses polite.
              </Message.Body>
            </Message>
          </Block>
          <Block>
            <Message color={'warning'}>
              <Message.Body size={8}> 
                <strong>Please Note:</strong> <br/> You have to provide a real email if you want me to respond. I will <strong>NOT</strong> anwser questions sent to me on any workplace systems. This is explained in the <a href='/faq'>FAQ</a> section.
                Also please ensure you actually monitor your email. Orders sent through the contact us page will <strong>NOT</strong> be accepted.
              </Message.Body>
            </Message>
          </Block>
        </Columns.Column>
    </Columns>
    <Columns multiline={false} centered>
      <Columns.Column size={8}>
        <Card id='contactUsForm'>
          <Card.Header.Title>
            Contact Us Form:
          </Card.Header.Title>
          <Card.Content>
            <Content>
              <form onSubmit={handleSubmit}>
                <Form.Field>
                  <Form.Label required>Name:</Form.Label>
                  <Form.Control>
                    <Form.Input
                      value={name}
                      onChange={(e) => {
                        setDisabled(false);
                        return setName(e.target.value);
                      }}
                    />
                    <Icon align="left" size="small">
                      <FaUser />
                    </Icon>
                  </Form.Control>
                  { errorName ? <span style={{ color: 'red', fontSize: '12px'}}>{errorName}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Label>Email:</Form.Label>
                  <Form.Control>
                    <Form.Input
                      value={email}
                      onChange={(e) => {
                        setDisabled(false);
                        return setEmail(e.target.value);
                      }}
                    />
                    <Icon align="left" size="small">
                      <MdEmail />
                    </Icon>
                  </Form.Control>
                  { errorEmail ? <span style={{ color: 'red', fontSize: '12px'}}>{errorEmail}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Control>
                    <Form.Label>Subject / Reason:</Form.Label>
                    <Form.Select
                      value={type}
                      onChange={(e) => {
                        setDisabled(false);
                        return setType(e.target.value);
                      }}
                    >
                      <option value="select">Select One</option>
                      <option value="complaint">Complaint</option>
                      <option value="concern">Concern</option>
                      <option value="thanks">Thank You</option>
                      <option value="questions">Question</option>
                      <option value="site-problems">Website Broken</option>
                      <option value="other">Other</option>
                    </Form.Select>
                  </Form.Control>
                  { errorType ? <span style={{ color: 'red', fontSize: '12px'}}>{errorType}</span> : ""}
                </Form.Field>
                <Form.Field>
                  <Form.Label>Message:</Form.Label>
                  <Form.Textarea
                    value={message}
                    onChange={(e) => {
                      setDisabled(false);
                      return setMessage(e.target.value);
                    }}
                  />
                  { errorMessage ? <span style={{ color: 'red', fontSize: '12px'}}>{errorMessage}</span> : ""}
                </Form.Field>
                <Form.Field >
                  <Button type='submit' color="success" disabled={disabled} onClick={(e) => {handleSubmit(e);}}>
                    Send
                  </Button>
                  <Button color="danger" onClick={(e) => {clearForm(e);}} >
                    Clear
                  </Button>
                </Form.Field>
              </form>
            </Content>
          </Card.Content>
        </Card>
      </Columns.Column>
    </Columns>
    </div>
  );
}

export default ContactUs