import React, {useState} from 'react';
import '../tireChalk/TrustedUserModal.css';
import { Content, Modal, Button, Form, Icon, Block, Message } from 'react-bulma-components';
import axios from 'axios';
import TireChalkWaitingModal from '../tireChalk/TireChalkWaitingModal';

import { 
  FaUser,
  FaKey,
  FaEnvelope
} from 'react-icons/fa';

import {
  MdVerifiedUser
} from 'react-icons/md';

const TrustedUserModal = (props) => {

  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showVerificationField, setShowVerificationField] = useState(false);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [errorUsername, setErrorUsername] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorVerificationCode, setErrorVerificationCode] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [errorLastName, setErrorLastName] = useState('');

  const [processing, setProcessing] = useState(false);

  const [trustedUserLogin, setTrustedUserLogin] = useState({
    username: "",
    password: "",
    verificationCode: null,
    sessionUUID: ""
  });

  const [trustedUserRegistration, setTrustedUserRegistration] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: ""
  });

  const clearTrustedUserForm = () => {
    let clearedUserLogin = trustedUserLogin;
    clearedUserLogin.username = '';
    clearedUserLogin.password = '';
    clearedUserLogin.verificationCode = '';
    setTrustedUserLogin(clearedUserLogin);
    setShowPassword(false);
    setErrorUsername('');
    setErrorPassword('');
    setErrorVerificationCode('');
  }

  const clearTrustedUserRegistrationForm = () => {
    let clearedUserRegistration = trustedUserRegistration;

    clearedUserRegistration.firstName = '';
    clearedUserRegistration.lastName = '';
    clearedUserRegistration.email = '';
    clearedUserRegistration.username = '';
    clearedUserRegistration.password = '';
    clearedUserRegistration.verificationCode = '';
    setTrustedUserRegistration(clearedUserRegistration);

    setErrorFirstName('');
    setErrorLastName('');
    setErrorUsername('');
    setErrorPassword('');
    setShowPassword(false);
    setErrorEmail('');
    setErrorVerificationCode('');
  }

  const clearErrors = () => {
    setErrorFirstName('');
    setErrorLastName('');
    setErrorUsername('');
    setErrorPassword('');
    setShowPassword(false);
    setErrorEmail('');
    setErrorVerificationCode('');
  }

  const closeModal = () => {
    clearErrors();
    {showRegistrationForm ? clearTrustedUserRegistrationForm() : clearTrustedUserForm()}
    setShowLoginForm(true);
    setShowVerificationField(false);
    setShowRegistrationForm(false);
    setShowPassword(false);
    props.setActive(false);
  }

  const registerUser = async() => {
    setProcessing(true);
    await axios.post("/api/v1.2/tireChalk/trustedRegisterUser", trustedUserRegistration)
    .then( (res) => {
      setErrorFirstName('');
      setErrorLastName('');
      setErrorUsername('');
      setErrorPassword('');
      setErrorEmail('');
      setErrorVerificationCode('');
      clearTrustedUserRegistrationForm();
      setShowRegistrationForm(false);
      setShowLoginForm(true);
      setShowVerificationField(true);
      setProcessing(false);
    })
    .catch(function (error){
      setProcessing(false);
      if(error.response.data.fieldErrors){
        setErrorFirstName('');
        setErrorLastName('');
        setErrorUsername('');
        setErrorPassword('');
        setErrorEmail('');
        setErrorVerificationCode('');

        error.response.data.fieldErrors.forEach(fieldError => {
          if(fieldError.field === 'username'){
            setErrorUsername(fieldError.message);
          }
          if(fieldError.field === 'UniqueUsername'){
            setErrorUsername(fieldError.message);
          }
          if(fieldError.field === 'VerifyUsernamePassword'){
            setErrorUsername(fieldError.message);
          }
          if(fieldError.field === 'password'){
            setErrorPassword(fieldError.message);
          }
          if(fieldError.field === 'VerifyUsernamePassword'){
            setErrorPassword(fieldError.message);
          }
          if(fieldError.field === 'firstName'){
            setErrorFirstName(fieldError.message);
          }
          if(fieldError.field === 'lastName'){
            setErrorLastName(fieldError.message);
          }
          if(fieldError.field === 'email'){
            setErrorEmail(fieldError.message);
          }
          if(fieldError.field === 'UniqueEmail'){
            setErrorEmail(fieldError.message);
          }
        });
      }
    });
  }

  const loginUser = async() => {

    let user = trustedUserLogin;
    user.sessionUUID = localStorage.getItem("sessionUUID");
    setTrustedUserLogin(user);

    await axios.post("/api/v1.2/tireChalk/trustedUserLogin", trustedUserLogin)
    .then( (res) => {
      setErrorUsername('');
      setErrorPassword('');
      setErrorVerificationCode('');

      localStorage.setItem('trustedChalkUser', res.data);
      localStorage.setItem('trustedChalkUserPassword', trustedUserLogin.password);

      props.setCurrentUser(trustedUserLogin.username);
      props.setCurrentUserPassword(trustedUserLogin.password);

      clearTrustedUserForm();
      closeModal();
    })
    .catch(function (error){    
      if(error.response.data.fieldErrors){
        setErrorUsername('');
        setErrorPassword('');
        setErrorVerificationCode('');
        
        error.response.data.fieldErrors.forEach(fieldError => {
          if(fieldError.field === 'username'){
            setErrorUsername(fieldError.message);
          }
          if(fieldError.field === 'VerifyUsernamePassword'){
            setErrorUsername(fieldError.message);
          }
          if(fieldError.field === 'password'){
            setErrorPassword(fieldError.message);
          }
          if(fieldError.field === 'VerifyUsernamePassword'){
            setErrorPassword(fieldError.message);
          }
          if(fieldError.field === 'IsVerified'){
            setShowVerificationField(true);
            setErrorVerificationCode(fieldError.message);
          }
        });
      }
    });
  }

  const verifyUserCode = async() => {
    await axios.post("/api/v1.2/tireChalk/trustedUserVerificationLogin", trustedUserLogin)
    .then( (res) => {
      setErrorUsername('');
      setErrorPassword('');
      setErrorVerificationCode('');

      localStorage.setItem('trustedChalkUser', res.data);
      localStorage.setItem('trustedChalkUserPassword', trustedUserLogin.password);

      props.setCurrentUser(localStorage.getItem('trustedChalkUser'));
      props.setCurrentUserPassword(localStorage.getItem('trustedChalkUserPassword'));

      clearTrustedUserForm();
      closeModal();
    })
    .catch(function (error){    
      if(error.response.data.fieldErrors){
        setErrorUsername('');
        setErrorPassword('');
        setErrorVerificationCode('');
        
        error.response.data.fieldErrors.forEach(fieldError => {
          if(fieldError.field === 'username'){
            setErrorUsername(fieldError.message);
          }
          if(fieldError.field === 'VerifyUsernamePassword'){
            setErrorUsername(fieldError.message);
          }
          if(fieldError.field === 'password'){
            setErrorPassword(fieldError.message);
          }
          if(fieldError.field === 'VerifyUsernamePassword'){
            setErrorPassword(fieldError.message);
          }
          if(fieldError.field === 'IsVerified'){
            setShowVerificationField(true);
            setErrorVerificationCode(fieldError.message);
          }
          if(fieldError.field === 'verificationCode'){
            setErrorVerificationCode(fieldError.message);
          }
          if(fieldError.field === 'VerifyValidationCode'){
            setErrorVerificationCode(fieldError.message);
          }
        });
      }
    });
  }

  return (
  <div>
    <Modal show={props.active ? true : false} onClose={()=>{closeModal(); }}>
    <TireChalkWaitingModal active={processing}/>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>
            Trusted User {showLoginForm ? "Login" : "Registration"}:
          </Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <Content>
            <form>
            { showLoginForm &&
              <>
              <Form.Field>
                <Form.Label required>Username:</Form.Label>
                <Form.Control>
                  <Form.Input
                    value={trustedUserLogin.username}
                    name="userID"
                    onChange={(e) => {
                      setTrustedUserLogin({...trustedUserLogin, username: e.target.value});
                    }}
                  />
                  <Icon align="left" size="small">
                    <FaUser />
                  </Icon>
                </Form.Control>
                { errorUsername && <span className='errorFieldMessage'>{errorUsername}</span>}
              </Form.Field>
              <Form.Field>
                <Form.Label>Password:</Form.Label>
                <Form.Control>
                  <Form.Input
                    value={trustedUserLogin.password}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      setTrustedUserLogin({...trustedUserLogin, password: e.target.value});
                    }}
                  />
                  <Icon align="left" size="small">
                    <FaKey />
                  </Icon>
                  <button className='showPasswordButton' onClick={(e) => {e.preventDefault(); setShowPassword(!showPassword);}}>{showPassword ? 'Hide Password' : 'Show Password'}</button>
                </Form.Control>
                { errorPassword && <span className='errorFieldMessage'>{errorPassword}</span>}
              </Form.Field>
              { showVerificationField &&
              <Form.Field>
                <Block className='ddlWarningTireChalk'>
                  <Message color={'warning'} size={'small'}>
                    <Message.Body>
                      <strong>Important: </strong> This was sent to your email. Include the dash with your code! 
                    </Message.Body>
                  </Message>
                </Block>
                <Form.Label>Verification Code:</Form.Label>
                <Form.Control>
                  <Form.Input
                    value={trustedUserLogin.verificationCode}
                    name="verificationCode"
                    onChange={(e) => {
                      setTrustedUserLogin({...trustedUserLogin, verificationCode: e.target.value});
                    }}
                  />
                  <Icon align="left" size="small">
                    <MdVerifiedUser />
                  </Icon>
                </Form.Control>
                { errorVerificationCode && <span className='errorFieldMessage'>{errorVerificationCode}</span>}
              </Form.Field>
              }
              </>
            }
            { showRegistrationForm &&
              <>
              <Form.Field>
                <Block className='ddlWarningTireChalk'>
                  <Message color={'danger'} size={'small'}>
                    <Message.Body>
                      <strong>Important: </strong> If I cannot verify you work in the workplace your account will be deactivated.
                    </Message.Body>
                  </Message>
                </Block>
                <Form.Label required>First Name:</Form.Label>
                <Form.Control>
                  <Form.Input
                    value={trustedUserRegistration.firstName}
                    name="firstName"
                    onChange={(e) => {
                      setTrustedUserRegistration({...trustedUserRegistration, firstName: e.target.value});
                    }}
                  />
                </Form.Control>
                { errorFirstName && <span className='errorFieldMessage'>{errorFirstName}</span>}
              </Form.Field>
              <Form.Field>
                <Form.Label>Last Name:</Form.Label>
                <Form.Control>
                  <Form.Input
                    value={trustedUserRegistration.lastName}
                    name="lastName"
                    onChange={(e) => {
                      setTrustedUserRegistration({...trustedUserRegistration, lastName: e.target.value});
                    }}
                  />
                </Form.Control>
                { errorLastName && <span className='errorFieldMessage'>{errorLastName}</span>}
              </Form.Field>
              <Form.Field>
                <Form.Label>Username:</Form.Label>
                <Block className='ddlWarningTireChalk'>
                  <Message color={'warning'} size={'small'}>
                    <Message.Body>
                      <strong>Important: </strong> This is your login! It will also appear on you Trusted User report badge. 
                    </Message.Body>
                  </Message>
                </Block>
                <Form.Control>
                  <Form.Input
                    value={trustedUserRegistration.username}
                    name="username"
                    onChange={(e) => {
                      setTrustedUserRegistration({...trustedUserRegistration, username: e.target.value});
                    }}
                  />
                  <Icon align="left" size="small">
                    <FaUser />
                  </Icon>
                </Form.Control>
                { errorUsername && <span className='errorFieldMessage'>{errorUsername}</span>}
              </Form.Field>
              <Form.Field>
                <Form.Label>Password:</Form.Label>
                <Form.Control>
                  <Form.Input
                    value={trustedUserRegistration.password}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      setTrustedUserRegistration({...trustedUserRegistration, password: e.target.value});
                    }}
                  />
                  <Icon align="left" size="small">
                    <FaKey />
                  </Icon>
                  <button className='showPasswordButton' onClick={(e) => {e.preventDefault(); setShowPassword(!showPassword);}}>{showPassword ? 'Hide Password' : 'Show Password'}</button>
                </Form.Control>
                
                { errorPassword && <span className='errorFieldMessage'>{errorPassword}</span>}
              </Form.Field>
              <Form.Field>
                <Form.Label>Email:</Form.Label>
                <Block className='ddlWarningTireChalk'>
                  <Message color={'warning'} size={'small'}>
                    <Message.Body>
                      <strong>Important: </strong> You need to verify your account! Do NOT use a fake email, the system will delete your account if it is not able to successfully send you a code! 
                    </Message.Body>
                  </Message>
                </Block>
                <Form.Control>
                  <Form.Input
                    value={trustedUserRegistration.email}
                    name="email"
                    onChange={(e) => {
                      setTrustedUserRegistration({...trustedUserRegistration, email: e.target.value});
                    }}
                  />
                  <Icon align="left" size="small">
                    <FaEnvelope />
                  </Icon>
                </Form.Control>
                { errorEmail && <span className='errorFieldMessage'>{errorEmail}</span>}
              </Form.Field>
              </>
            }
            </form>
          </Content>
        </Modal.Card.Body>
        <Modal.Card.Footer>
          <div style={{width: '100%'}}>
          <Button color="success" onClick={()=> {showVerificationField ? verifyUserCode() : showRegistrationForm ? registerUser() : showLoginForm && loginUser()}}>
            {showLoginForm ? 'Login' : showRegistrationForm ? 'Register' : showVerificationField && 'Verify Account' }
          </Button>
          <Button color="danger" onClick={()=> {closeModal();}}>
            Cancel
          </Button>
          </div>
          <div>
            { !showRegistrationForm ?
            <button className='showAsLink' onClick={()=> {clearTrustedUserForm(); clearTrustedUserRegistrationForm(); setShowLoginForm(false); setShowVerificationField(false); setShowRegistrationForm(true); }}>
              Register as Trusted User
            </button>
            :
            <button className='showAsLink' onClick={()=> {clearTrustedUserForm(); clearTrustedUserRegistrationForm(); setShowLoginForm(true); setShowVerificationField(false); setShowRegistrationForm(false);}}>
              Back to Login Page
            </button>
            }
          </div>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  </div>
  )
}

export default TrustedUserModal