import React, { useEffect, useState } from 'react';
import '../itcProjects/ItcProjects.css';
import axios from 'axios';

import { Columns, Container, Heading, Hero } from 'react-bulma-components';
import { isFirefox } from 'react-device-detect';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useNavigate, useLocation } from 'react-router-dom';

import Spinner from '../../components/loadingSpinner/Spinner';
import ProjectCard from '../../components/projectCard/ProjectCard';
import NoContent from '../../components/noContent/NoContent';
import PendingProjectCard from '../../components/pendingProjectCard/PendingProjectCard';
import ChangeModal from '../../components/changeModal/ChangeModal';
import WaitingModal from '../../components/waitingModal/WaitingModal';
import SuccessBanner from '../../components/successBanner/SuccessBanner';

const ItcProjects= (props) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [projects, setProjects] = useState([]);
  const [noContent, setNoContent] = useState(false);
  const [approvedProjects, setApprovedProjects] = useState([]);
  const [pendingProjects, setPendingProjects] = useState([]);
  const { promiseInProgress } = usePromiseTracker();

  const [active, setActive] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [id, setId] = useState("");
  const [checkType, setCheckType] = useState("");

  const [bannerMessage, setBannerMessage] = useState(location?.state?.bannerMessage ? location.state.bannerMessage : "");
  const [bannerColor, setBannerColor] = useState(location?.state?.bannerColor ? location.state.bannerColor : "success");
  const [pendingProject, setPendingProject] = useState(false);


  const loadPendingProjects = () => {
    trackPromise(
      fetch("/api/projects/itcProjects/pending")
      .then((res) => res.json())
      .then((result) => {
        setPendingProjects(result);
      })
      .catch((err) => {
        if(pendingProjects.length === 0 && noContent === false){
          setNoContent(true);
        }
      })
    )

  }

  const loadApprovedProjects = () => {
    trackPromise(
      fetch("/api/projects/itcProjects")
      .then((res) => res.json())
      .then((result) => {
        setApprovedProjects(result);
      })
      .catch((err) => {
        if(pendingProjects.length === 0 && noContent === false){
          setNoContent(true);
        }
      })
    )
  }

  useEffect(() => {
    loadApprovedProjects();
    loadPendingProjects();
  },[])

  useEffect(() => {
    setTimeout(() => {setBannerMessage("");}, 7000);
      setBannerColor("");
  },[bannerMessage]);

  const deleteProject = async (id, editCode, valid) => {
    
    if(valid){
      setProcessing(true);
      const result = await axios.delete(`/api/projects/delete/${id}`, {data:editCode})
                          .then(()=>{ setBannerMessage("The project was successfully nuked. We are sorry to see the project leave the platform."); setBannerColor("success"); loadPendingProjects();})
                          .catch(error => {console.log(error);});
    }
    setActive(false);
    setProcessing(false);
  }

  const editProject = async (id, editCode, valid, pending) => {
    if(valid){
      if(pending){
        navigate(`/itcProjects/edit/pending/project/${id}`, {state: {editCode: editCode, valid: valid}});
      }else{
        navigate(`/itcProjects/edit/project/${id}`, {state: {editCode: editCode, valid: valid}});
      }
    }
    setActive(false);
  }

  return (
    <div id='itcProjectsPage'>
    <WaitingModal active={processing} message="delete"/>
    {checkType === "edit" ?
      <ChangeModal setActive={setActive} active={active ? true : false} id={id} action={editProject} pending={pendingProject}/>
    :
      <ChangeModal setActive={setActive} active={active ? true : false} id={id} action={deleteProject} />
    }
    <Columns centered="true" className={isFirefox ? "ff": ""} >
      <Columns.Column size={6}>
        <Hero color={'dark'} size={'small'}>
          <Hero.Body>
            <Container>
              <Heading weight={'bold'}>
                I2C Projects
              </Heading>
            </Container>
          </Hero.Body>
        </Hero>
      </Columns.Column>
      { bannerMessage!== "" ? <Columns.Column size={8}><SuccessBanner message={bannerMessage} bannerColor={bannerColor}/></Columns.Column> : <></> }
      <Spinner />
      { !promiseInProgress &&
      <Columns.Column size={12} centered="true">
        <Columns className={isFirefox ? "ff": ""}>
          {noContent ? <NoContent /> : <></>}
          {approvedProjects.map(project => (
            <Columns.Column size={4} key={project.id}>
              <ProjectCard projectId={project.id} projectTitle={project.title} projectOwner={project.name} imageFile={project.imagePath} itc={true} bannerMessageSetter={setBannerMessage}/>
            </Columns.Column>
          ))}
          {pendingProjects.map(project => (
            <Columns.Column size={4} key={project.id}>
              <PendingProjectCard setActive={setActive} setId={setId} projectId={project.id} setCheckType={setCheckType} setPendingProject={setPendingProject}/>
            </Columns.Column>
          ))}
        </Columns>
      </Columns.Column>}
    </Columns>
    </div>
  )
}

export default ItcProjects