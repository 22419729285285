import React, { useEffect } from 'react';

import { Sidebar as ProSidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import { NavLink as Link, useNavigate } from 'react-router-dom';
import { Image } from 'react-bulma-components'
import ControlledSwitch from 'react-bulma-switch';

import { SiBuymeacoffee } from "react-icons/si";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import Icon from '@mdi/react';
import { 
  mdiHomeLightbulbOutline,
  mdiLightbulb,
  mdiLightbulbGroup,
  mdiLightbulbQuestion,
  mdiFireAlert,
  mdiAccountKey,
  mdiTire
} from '@mdi/js';

import "../sidebar/Sidebar.css";
import { isMobile } from 'react-device-detect';
import useTokenStore from '../../utils/store';
import { RequiredAuthRoles } from '../../utils/util';

import i2cIconLowRes from '../../assets/i2c_background_lr.jpg';

const Sidebar = (props) => {
  const jwt = useTokenStore((state) => state.jwt);
  const { collapseSidebar, collapsed } = useProSidebar();
  const navigate = useNavigate();

  const menuIconClick = () => {
    collapseSidebar();
    props.func(!collapsed);
  };

  const loginLogout = () => {
    document.title = 'I2C Platfrom';
    return jwt ? navigate("/login", {state: {expireLogin: true}}) : navigate("/login");
  }

  useEffect(() => {
    if(isMobile){
      menuIconClick();
    }
  }, []);

  return (
    <div id='sidebar' className={ collapsed ? "small-nav" : "large-nav" } >
      <ProSidebar>
          {collapsed ? 
            <div className="logotext" style={{padding: '10px 5px 0px'}}>
              {collapsed ? (<Image src={i2cIconLowRes} size='square' rounded={true} onClick={loginLogout}/> ) : (<Image src={i2cIconLowRes} size='square' rounded={true} onClick={loginLogout}/>)}
              <p style={{paddingTop: '15px'}}>{collapsed ? "I2C" : "I2C Platform"}</p>
            </div>
          :
            <div className="logotext" style={{padding: '20px 20px 0px 20px'}}>
              {collapsed ? (<Image src={i2cIconLowRes} size='square' rounded={true} onClick={loginLogout}/>) : (<Image src={i2cIconLowRes} size='square' rounded={true} onClick={loginLogout}/>)}
              <p style={{paddingTop: '15px'}}>{collapsed ? "I2C" : "I2C Platform"}</p>
            </div>
          }
          

          <hr style={{margin: '0.5rem'}}/>
          
          <div id="collapseMenuToggleButton" style={{padding: '10px', textAlign: 'center', color: 'white'}}>
            <ControlledSwitch  value={!collapsed} thin={true} onChange={(e) => {menuIconClick();}}/>
          </div>

        <Menu iconShape="square">
          {/* <object>
            <AiOutlineMenuFold onClick={(e) => {menuIconClick();}}/>
          </object> */}
          <object>
            <MenuItem component={<Link onClick={(e) => {document.title = 'I2C Platfrom';}} to='/home'/>} icon={<Icon color="#8B8000" path={mdiHomeLightbulbOutline}/>}>
              Home / About
            </MenuItem>
          </object>
          <object>
            <MenuItem component={<Link  onClick={(e) => {document.title = 'I2C Platfrom';}} to='/itcProjects'/>} icon={<Icon className="blink_me" color="#CC5500" path={mdiLightbulb}/>}>
              I2C Projects
            </MenuItem>
          </object>
          <object>
            <MenuItem component={<Link onClick={(e) => {document.title = 'I2C Platfrom';}} to='/communityProjects'/>} icon={<Icon color="#486856" path={mdiLightbulbGroup}/>}>
              CMTY Projects
            </MenuItem>
          </object>
          <object>
            <MenuItem component={<Link onClick={(e) => {document.title = 'I2C - Tire Chalk App';}} to='/tireChalk'/>} icon={<Icon color="black" path={mdiTire}/>}>
              Tire Chalk
            </MenuItem>
          </object>
          <object>
            <MenuItem component={<Link onClick={(e) => {document.title = 'I2C Platfrom';}} to='/faq'/>} icon={<Icon color="#7A4988" path={mdiLightbulbQuestion}/>} >
              FAQ
            </MenuItem>
          </object>
          <object>
            <MenuItem component={<Link onClick={(e) => {document.title = 'I2C Platfrom';}} to='/contactUs'/>} icon={<Icon color="#8B0000" path={mdiFireAlert}/>} >
              Contact Us
            </MenuItem>
          </object>
          <object>
            <MenuItem className={isMobile ? "bmcIcon" : "bmcIcon"} component={<Link  to='https://www.buymeacoffee.com/ideastocreation'/>} icon={<SiBuymeacoffee color="#573727" size={30}/>} >
              BuyMeACoffee
            </MenuItem>
          </object>
          {props.evaluateRoles ?
          <>
          <RequiredAuthRoles allowedRoles={["ROLE_80085"]}>
            <object>
              <MenuItem component={<Link onClick={(e) => {document.title = 'I2C Platfrom';}} to='/admin'/>} icon={<Icon color="black" path={mdiAccountKey}/>} >
                Admin Page
              </MenuItem>
            </object>
          </RequiredAuthRoles>
          {/* <MenuItem  icon={<Icon color="black" path={mdiDoorOpen}/>} onClick={()=>{performLogout();}}>
            Logout
          </MenuItem> */}
          </>
          :
          <>
          {/* <MenuItem component={<Link  to='/login'/>} icon={<Icon color="black" path={mdiDoorClosed}/> } >
            Login
          </MenuItem> */}
          </>
          }
        </Menu>
        
      </ProSidebar>
    </div>
  )
}

export default Sidebar