import React, { useEffect } from 'react'
import '../tireChalk/TireChalkWaitingModal.css'

import { ThreeDots } from "react-loader-spinner";
import {  Modal } from 'react-bulma-components'

const TireChalkWaitingModal = (props) => {

  return (
    <div>
      <Modal show={props.active ? true : false}>
        <Modal.Card>
          <div className="spinner">
          <ThreeDots color="yellow" height="100" width="100" />
          <strong><h1>Potato of a server is attempting to create your account ...</h1></strong>
          </div>
          
        </Modal.Card>
      </Modal>
    </div>
    )
}

export default TireChalkWaitingModal