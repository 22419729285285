import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken, isSupported } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyClxUp97k8JmT3S3r6GxAt510Thdkpso_Y",
  authDomain: "i2c-space.firebaseapp.com",
  projectId: "i2c-space",
  storageBucket: "i2c-space.appspot.com",
  messagingSenderId: "282013529567",
  appId: "1:282013529567:web:0664b31426c22fe1b5c06a"
};


const firebaseApp = initializeApp(firebaseConfig);
let messaging = null;
const support = isSupported().then((res) => {
  if(res === true){
    messaging = getMessaging(firebaseApp);
    return getMessaging(firebaseApp);
  }
  return null;
})

export const areNotificationsSupported = async() => {
  let supported = false;
  await isSupported().then((res)=> { res === true ? supported=true : supported=false;}).catch((e)=>{supported = false;});
  return supported;
}

export const fetchToken = (setFcmClientToken) => {


  if(messaging !== null && isSupported()){
    return getToken(messaging, { vapidKey: `BNjtpjMe__d2VB7xHpPc5MPFGP6HiwuiR7VQMbkKbMCqxX9nsRDg8DTmGhvWfWnLUsMyQLOE890hcIASSd_uWBU` })
      .then( async(currentToken) => {
        if (currentToken) {
          setFcmClientToken(currentToken);
          localStorage.setItem('fcmClientToken', currentToken);
          //console.log('current token for client: ', currentToken);
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }else{
    console.log('Browser does not support notifications.');
    return null;
  }
  

};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export const deleteFcmToken = () => {
  deleteToken(messaging);
  localStorage.removeItem("fcmTokenLocation");
  localStorage.removeItem("fcmClientToken");
};

