import React from 'react';
import '../home/Home.css';

import { Button, Columns, Container, Heading, Hero, Panel } from 'react-bulma-components';
import { useNavigate } from 'react-router-dom';

const Home = () => {

  const navigate = useNavigate();

  return (
      <>
      <div>
        <Columns id='homePage' centered="true">
          <Columns.Column size={6}>
            <Hero color={'dark'} size={'small'}>
              <Hero.Body>
              <Container>
                <Heading weight={'bold'}>
                  Welcome To The I2C Platform
                </Heading>
              </Container>
              </Hero.Body>
            </Hero>
          </Columns.Column>
          <Columns.Column size={10} className="aboutUs">
            <Panel color={'dark'}>
              <Panel.Header>
                Home / About Us:
              </Panel.Header>
              <Panel.Block className='mainContent'>
                <span>
                  Welcome to the <strong className='aboutUs'><em>I2C Platform</em></strong>. 
                  As promised I devoted some time to providing a better user experience. 
                  For those of you that are new here welcome to the platform. 
                  Here you will find a list of maker projects aimed at solving some of those everyday eye-rolling problems at the workplace. 
                  Nothing life-changing but the issues everyone seems to both hate and be fine with but secretly wish there was something that would fix it.
                  <br/><br/>
                  
                  This platform is a <strong className='aboutUs'>free-to-use </strong> 
                  platform aimed at reducing the use of workplace resources for personal use while still promoting creativity and innovation within the workplace. 
                  I hope the platform will provide a home for the projects currently residing on the workplace systems.
                  <br/><br/>
                  
                  So I invite all the <strong><em>Makers</em></strong>, <strong><em>Hobbyists</em></strong>, <strong><em>Crafters</em></strong>, 
                  <strong><em> Tinkers</em></strong> and <strong><em>any other creative individual </em></strong>
                  to add their projects to the platform. These projects can be free, paid, or of an FYI nature. 
                  The projects added by the workplace community will appear in the <strong><em>Community Projects</em></strong> page of the platform.
                  You will be responible for managing your project and conducting it ethically. 
                  While this site is not managed or associated with the workplace in any way I recommend doing your due diligence and a little research before adding your project to the platform. 
                  There are resources within the workplace responsible for these things. 
                  <strong><em> Please note all projects within the I2C Projects page are approved by the workplace and ethically managed by myself. </em></strong> 
                  Lastly, I hope you enjoy and find something that sparks your interest or inspires you to create your own project.
                </span>
              </Panel.Block>
            </Panel>
          </Columns.Column>
          <Columns.Column size={5} className="aboutUs" vcentered="true">
              <Button className='i2cButton is-fullwidth' onClick={(e)=>{navigate("/itcProjects");}}>Take me to I2C Projects</Button>
          </Columns.Column>
          <Columns.Column size={5} className="aboutUs" vcentered="true">
              <Button  className='cmtyButton is-fullwidth' onClick={(e)=>{navigate("/communityProjects");}}>Take me to Community Projects</Button>
          </Columns.Column>


          <Columns.Column size={10} className="aboutUs" vcentered="true">
          <Panel color={'dark'}>
              <Panel.Header>
                Disclaimer:
              </Panel.Header>
              <Panel.Block className='mainContent'>
                <span>
                  Community projects are in no way associated with the I2C creator. The I2C creator takes no credit for the projects and does not manage them. By requesting the I2C Platform to host 
                  your project you are taking full responsibility for your content and your project with the understanding that the I2C Platform is under no obligations or liability for any <em>Community Project</em>.
                </span>
              </Panel.Block>
            </Panel>
          </Columns.Column>
        </Columns>
        </div>
      </>
  );
}

export default Home
