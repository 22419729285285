import React, { useEffect, useState } from 'react';
import '../faq/Faq.css';

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import axios from 'axios';
import { RequiredAuthRoles } from '../../utils/util';
import useIsAdmin from '../../utils/util';
import useTokenStore from '../../utils/store';

import { Form, Button, Card, Columns, Hero, Container, Heading, Content, Block, Message} from 'react-bulma-components';
import ControlledSwitch from 'react-bulma-switch';

import Questions from '../../components/questions/Questions';
import NoContent from '../../components/noContent/NoContent';
import Spinner from '../../components/loadingSpinner/Spinner';

const Faq = () => {

  const jwt = useTokenStore((state) => state.jwt);

  const [questions, setQuestions] = useState([]);

  const [question, setQuestion] = useState({
    id: null,
    title: "",
    answer: "",
    active: true,
    type: 0
  });

  const [noContent, setNoContent] = useState();
  const [showType, setShowType] = useState(0);
  const { promiseInProgress } = usePromiseTracker();
  const isAdmin = useIsAdmin();
  const [disabled, setDisabled] = useState(true);
  const [editing, setEditing] = useState(false);

  const [errorTitle, setErrorTitle] = useState("");
  const [errorAnswer, setErrorAnswer] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorActive, setErrorActive] = useState("");

  // const toggleType = (e) => {
  //   if(showType === 0){
  //     setShowType(1);
  //   }else{
  //     setShowType(0);
  //   }
  // }

  const updateActiveState = async(question) => {
    await axios
      .post("/api/faq/update/"+ question.id, question, {
        headers: {
          'Authorization': `Bearer ${jwt}`
        }
      })
      .then(() => {
        reloadQuestions();
      });
  }

  const editFaq = async(q) => {
    setEditing(true);
    setQuestion(q);
    setDisabled(false);
  }

  const deleteFaq = async(id) => {
    await axios.delete("/api/faq/delete/"+ id,
      {
        headers: {
          'Authorization': `Bearer ${jwt}`
        }
      })
      .then(reloadQuestions());
  }

  const reloadQuestions = async() => {
    const reloadedQuestions = 
    trackPromise(
     fetch("/api/faq")
        .then(res => res.json())
        .then((result) => {
          setQuestions(result);
          setNoContent(false);
        })
        .catch((err) => {
          setNoContent(true);
        })
    )
      
  }

  const submitFaq = async(e) => {
    e.preventDefault();

    await axios
      .post(editing ? "/api/faq/update/" + question.id : "/api/faq/create", question, 
      {
        headers: {
          'Authorization': `Bearer ${jwt}`
        }
      })
      .then( ()=> {
        clearForm(e);
        setErrorTitle("");
        setErrorAnswer("");
        setErrorType("");
        setErrorActive("");

        if(editing){
          reloadQuestions();
        }
    
        if(!editing){
          reloadQuestions();
        }
      })
      .catch(function (error){    
        if(error.response.data.fieldErrors){
          setErrorTitle("");
          setErrorAnswer("");
          setErrorType("");
          setErrorActive("");

          error.response.data.fieldErrors.forEach(fieldError => {
            if(fieldError.field === 'title'){
              setErrorTitle(fieldError.message);
            }
            if(fieldError.field === 'answer'){
              setErrorAnswer(fieldError.message);
            }
            if(fieldError.field === 'active'){
              setErrorActive(fieldError.message);
            }
            if(fieldError.field === 'type'){
              setErrorType(fieldError.message);
            }
          });
        }
      })
  }

  const clearForm = (e) => {
    e.preventDefault();
    setDisabled(true);
    setQuestion({...question, id: null, title: "", answer: "", type: 0, active: true});
    setEditing(false);

    setErrorTitle("");
    setErrorAnswer("");
    setErrorType("");
    setErrorActive("");
  };

  const onInputChange = (e) => {
    setDisabled(false);
    setQuestion({...question, [e.target.name]: e.target.value});
  };

  useEffect(() => {
    reloadQuestions()
  },[])

  return (
    <div id='faqPage'>
      <Columns multiline={false}>
        <Columns.Column size={6} offset={3}>
          <Hero color={'dark'} size={'small'}>
            <Hero.Body>
              <Container>
                {isAdmin ? 
                <Heading weight={'bold'}>
                  FAQ - Frequently Asked Questions 
                </Heading>
                :
                <Heading weight={'bold'}>
                  FAQ - Frequently Asked Questions {showType === 0 ? " - I2C" : showType === 1 ? " - CMTY" : showType === 2 && "- Tire Chalk App"}
                </Heading>
                }
              </Container>
            </Hero.Body>
          </Hero>
        </Columns.Column>
      </Columns>
      {noContent && <NoContent />}
      <Spinner />
      {!isAdmin &&
      <Columns multiline={false} centered="true">
        <Columns.Column size={9}>
          <Block>
            <Message color={'info'}>
              <Message.Body>
                <div className='faqToggleBannerText'>
                <Columns multiline={false} centered="true">
                <Columns.Column size={9}>
                  <div>
                    <strong style={{color:'black'}}>Click the toggle to switch between Community Projects General Questions and I2C Platform questions</strong>
                  </div>
                  </Columns.Column>
                  <Columns.Column size={3}>


                  <Form.Control>
                  <Form.Select
                        name='showType'
                        value={showType}
                        onChange={(e) => {
                          setShowType(e.target.value === "0" ? 0 : e.target.value === "1" ? 1 : 2);
                        }}
                      >
                        <option value="0">I2C</option>
                        <option value="1">CMTY</option>
                        <option value="2">Tire Chalk</option>
                      </Form.Select>
                  </Form.Control>

                  {/* <div className='faqToggleBannerButton'>
                    <ControlledSwitch value={showType === 0 ? true : false} thin={true} onChange={(e) => {toggleType(e);}}/>
                  </div> */}
                  </Columns.Column>
                  </Columns>
                </div>
              </Message.Body>
            </Message>
          </Block>
        </Columns.Column>
      </Columns>
      }
      <RequiredAuthRoles allowedRoles={["ROLE_80085"]}>
        <Columns multiline={false} centered="true">  
        <Columns.Column size={9}>
          <Card id='createQuestionForm'>
            <Card.Header.Title>
              {editing ? "Update FAQ" : "New FAQ:"}
            </Card.Header.Title>
            <Card.Content>
              <Content>
                <form onSubmit={submitFaq}>
                  <Form.Field >
                    <Form.Label>Title / Question:</Form.Label>
                    <Form.Control >
                      <Form.Input
                      name='title'
                      value={question.title}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                      />
                    </Form.Control>
                    { errorTitle && <span className='errorMessage'>{errorTitle}</span>}
                  </Form.Field>
                  <Form.Field>
                    <Form.Label>Answer:</Form.Label>
                    <Form.Control>
                      <Form.Textarea
                        name='answer'
                        value={question.answer}
                        onChange={(e) => {
                          onInputChange(e);
                        }}
                      />
                    </Form.Control>
                    { errorAnswer && <span className='errorMessage'>{errorAnswer}</span>}
                  </Form.Field>
                  <Form.Field>
                    <Form.Label>Type:</Form.Label>
                    <Form.Control>
                    <Form.Select
                        name='type'
                        value={question.type}
                        disabled={disabled}
                        onChange={(e) => {
                          onInputChange(e);
                        }}
                      >
                        <option value="0">I2C</option>
                        <option value="1">CMTY</option>
                        <option value="2">Tire Chalk</option>
                      </Form.Select>
                    </Form.Control>
                    { errorType && <span className='errorMessage'>{errorType}</span>}
                  </Form.Field>
                  <Form.Field>
                    <Form.Label>Active:</Form.Label>
                    <div className='toggleButton'>
                      <ControlledSwitch  value={question.active} thin={true} onChange={(e) => {setQuestion({...question, active: !question.active}); setDisabled(false);}} /> 
                    </div>
                    { errorActive && <span className='errorMessage'>{errorActive}</span>}
                  </Form.Field>
                  <Form.Field >
                    <Button type='submit' color="success" disabled={disabled}  onClick={(e) => { submitFaq(e);}}>
                      {editing ? "Edit" : "Create"}
                    </Button>
                    <Button color="danger" disabled={disabled} onClick={(e) => {clearForm(e);}}>
                      Cancel
                    </Button>
                  </Form.Field>
                </form>
              </Content>
            </Card.Content>
          </Card>
        </Columns.Column>
      </Columns>
    </RequiredAuthRoles>
    <Questions questions={questions} updateActiveState={updateActiveState} deleteFaq={deleteFaq} editFaq={editFaq} isAdmin={isAdmin} showType={showType}/>
  </div>
  )
}

export default Faq