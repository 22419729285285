import React, { useEffect, useState } from 'react'
import {  Columns, Block, Message } from 'react-bulma-components'

const SuccessBanner = (props) => {

  const message = props.message;
  const [color, setColor] = useState("success");

  useEffect(()=>{
    if(props.bannerColor !== undefined){
      setColor(props.bannerColor);
    }
  },[])

  return (
    <Columns multiline={false} centered="true">
        <Columns.Column size={12}>
          <Block>
            <Message color={color}>
              <Message.Body><strong>{message}</strong></Message.Body>
            </Message>
          </Block>
        </Columns.Column>
    </Columns>
  )
}

export default SuccessBanner