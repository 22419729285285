import React, {useState, useEffect} from 'react'

import { Table, Box, Columns, Form, Button } from 'react-bulma-components';

import { 
  FaComments,
  FaUserSecret,
  FaEdit
} from 'react-icons/fa'

import {
  AiFillAlert
} from 'react-icons/ai'

import {
  HiBadgeCheck
} from 'react-icons/hi'

import { 
  GiMushroomCloud
} from 'react-icons/gi';
import TireChalkDeleteReportModal from './TireChalkDeleteReportModal';

const TireChalkTable = (props) => {
  
  const [showCommentsIcon, setShowCommentsIcon] = useState(true);
  const [showStatusText, setShowStatusText] = useState(true);
  const [showTireChalkDeleteModal, setShowTireChalkDeleteModal] = useState(false);
  const [tireChalkReportToBeDeleted, setTireChalkReportToBeDelete] = useState();

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );


  useEffect(() => {

      if(document.getElementById("tireChalkDesktopTable") != null){
        setShowCommentsIcon(document.getElementById('tireChalkDesktopTable').clientWidth > 850);
        setShowStatusText(document.getElementById('tireChalkDesktopTable').clientWidth > 800);
        if(document.getElementById('tireChalkDesktopTable').clientWidth < 600){
          props.setIsVerySmallWindow(true);
        }
      }else{
        props.setIsVerySmallWindow(true);
      }
    
    const updateMedia = () => {
        if(document.getElementById("tireChalkDesktopTable") != null){
          setShowCommentsIcon(document.getElementById('tireChalkDesktopTable').clientWidth > 850);
          setShowStatusText(document.getElementById('tireChalkDesktopTable').clientWidth > 800);

          if(document.getElementById('tireChalkDesktopTable').clientWidth < 600){
            props.setIsVerySmallWindow(true);
          }
        }else{
          props.setIsVerySmallWindow(true);
        }
    };

    const updateMediaWithDelay = async() => {

      await delay(200);

      if(document.getElementById("tireChalkDesktopTable") != null){
        setShowCommentsIcon(document.getElementById('tireChalkDesktopTable').clientWidth > 850);
        setShowStatusText(document.getElementById('tireChalkDesktopTable').clientWidth > 800);

        if(document.getElementById('tireChalkDesktopTable').clientWidth < 600){
          props.setIsVerySmallWindow(true);
        }
      }else{
        props.setIsVerySmallWindow(true);
      }
  };

    window.addEventListener("resize", updateMedia);
    const element = document.getElementById("collapseMenuToggleButton");
    element.addEventListener("click", updateMediaWithDelay);
    return () => {
      window.removeEventListener("resize", updateMedia)
      element.removeEventListener("click", updateMediaWithDelay)
    }
    
  },[]);

  return (
    <>
      <TireChalkDeleteReportModal 
        report={tireChalkReportToBeDeleted} 
        active={showTireChalkDeleteModal} 
        setActive={setShowTireChalkDeleteModal} 
        currentUserPassword={props.currentUserPassword} 
        getTireChalkReports={props.getTireChalkReports}
        isAdmin={props.isAdmin}
        jwt={props.jwt}
        showBannerMessage={props.showBannerMessage}
      />
      <div id='tireChalkDesktopTable' className='tableDiv'>
        <Box className='tableBox'>
        <Table className="table table-header-rotated">

          <thead>
            <tr className='table-header-tr'>
              <th colSpan={12} className='table-header-th'>
                <Columns multiline={false}>
                  <Columns.Column size={5}>
                    <div>
                      Street Statuses - {props.currentLocation === 0 ? "HS" : "LS"}:
                    </div>
                  </Columns.Column>
                  <Columns.Column size={5} >
                    <Columns >
                        <Button color='success' type='submit' onClick={() => {props.setShowChalkReportForm(!props.showChalkReportForm); props.getCurrentTime();}}><strong>New TireChalk Report</strong></Button>
                    </Columns>
                  </Columns.Column>
                  <Columns.Column size={2}>
                    {/* <Form.Control>
                      <Form.Select name='changeCurrentLocation' value={props.currentLocation} onChange={(e) => {props.setCurrentLocation(parseInt(e.target.value));}}>
                        <option value="0">HS</option>
                        <option value="1">LS</option>
                      </Form.Select>
                    </Form.Control> */}
                    <Button.Group renderAs='div' style={{position: "absolute"}}>
                      <Button className={props.currentLocation === 1 ? "activeLocationButton" : "inactiveLocationButton"} onClick={() => {props.setCurrentLocation(1);}}><strong>LS</strong></Button>
                      <Button className={props.currentLocation === 0 ? "activeLocationButton" : "inactiveLocationButton"} onClick={() => {props.setCurrentLocation(0);}}><strong>HS</strong></Button>
                    </Button.Group>
                  </Columns.Column>
                </Columns>
              </th>
            </tr>
            <tr >
              <th className="lastCheckedTitle">Entry Time:</th>
              {props.streetNames.map((street, i) => (
                (street.buildingLocation === props.currentLocation &&
                <th key={i} className="rotate"><div><span>{street.streetName}</span></div></th>
                )
              ))}
              <th className="comments">{!showCommentsIcon ? <FaComments size={20}/> : 'Comments:'}</th>
              {/* <th style={{borderWidth: '0px 0px 2px 1px', borderColor: 'black'}}></th> */}
              <th></th>
            </tr> 
          </thead>
          <tbody>
            {props.reports.filter((r) => r.buildingLocation === props.currentLocation).length > 0 ? 
              props.reports.map((report, i) => (
                report.buildingLocation === props.currentLocation &&
                <tr key={i}>
                  
                  <th className="row-header">{report.time}</th>
                  {report.streetStatuses.map((street, i) => (
                    (report.buildingLocation === props.currentLocation &&
                    <td key={i} className={street === 'uc' ? 'data' : street === 'nc' ? 'data green' : street === 'ch' ? 'data yellow' : street === 'ts' && 'data red'} >
                      {!showStatusText ? ' ' : street === 'uc' ? '' : street === 'nc' ? 'No Chalk' : street === 'ch' ? 'Chalk' : street === 'ts' && 'Tickets'}
                    </td>
                    )
                  ))}
                  {report.buildingLocation === props.currentLocation &&
                    <td className='data comments'>{report.comments}</td>
                  }
                  {props.isAdmin ? 
                    <td className='trustedReporterData'>
                      <div>
                        <FaEdit className='trustedReporterEditIcon' title='Edit Report' onClick={(e) => {props.setTireChalkReport(report); props.setStreets(report.streetStatuses); props.setShowChalkReportForm(true); props.setIsEditing(true);}}/>
                        <GiMushroomCloud  className='trustedReporterAlertIcon' title='Delete Report' onClick={(e) => {setTireChalkReportToBeDelete(report); setShowTireChalkDeleteModal(true);}}/>
                      </div>
                    </td>
                  : report.trustedUserReport ?
                    <td className='trustedReporterData'>
                      {props.currentUser !== report.trustedUserId ?
                        <div>
                          <HiBadgeCheck className='trustedReporterIcon' title={report.trustedUserId !== null ? report.trustedUserId : ''}/>
                          {/* <AiFillAlert className='trustedReporterAlertIcon' title='Report User'/> */}
                        </div>
                      : 
                        <div>
                          <FaEdit className='trustedReporterEditIcon' title='Edit Report' onClick={(e) => {props.setTireChalkReport(report); props.setStreets(report.streetStatuses); props.setShowChalkReportForm(true); props.setIsEditing(true);}}/>
                          <GiMushroomCloud  className='trustedReporterAlertIcon' title='Delete Report' onClick={(e) => {setTireChalkReportToBeDelete(report); setShowTireChalkDeleteModal(true);}}/>
                        </div>
                      }
                    </td>
                    : report.sessionUUID === localStorage.getItem("sessionUUID") ?
                      <td className='trustedReporterData'>
                        <div>
                          <FaEdit className='trustedReporterEditIcon' title='Edit Report' onClick={(e) => {props.setTireChalkReport(report); props.setStreets(report.streetStatuses); props.setShowChalkReportForm(true); props.setIsEditing(true);}}/>
                          <GiMushroomCloud  className='trustedReporterAlertIcon' title='Delete Report' onClick={(e) => {setTireChalkReportToBeDelete(report); setShowTireChalkDeleteModal(true);}}/>
                        </div>
                      </td>
                    :
                      <td title='Anonymous User' className='anonymousReporterData'><FaUserSecret/></td>
                  }
                </tr>
              ))
            :
              <tr>
                <td style={{borderRight: '1px solid', width: '140px'}}></td>
                {props.currentLocation === 0 ?
                <td colSpan={9} style={{border: 'none', textAlign: 'center'}}>No Content</td>
                :
                <td colSpan={8} style={{border: 'none', textAlign: 'center'}}>No Content</td>
                }
                <td style={{borderLeft: '1px solid', width: '450px'}}></td>
                <td style={{width: '2%', borderWidth: '0px 0px 0px 1px', borderColor: 'black'}}></td>

              </tr>
            }
          </tbody>
       
        </Table>
        
        </Box>
      </div>
      </>
  )
}

export default TireChalkTable