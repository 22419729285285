import React from 'react'
import "./Spinner.css";

import { usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";

import { Columns } from 'react-bulma-components';

const Spinner = () => {

  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <Columns.Column size={12} >
        <div className="spinner">
          <ThreeDots color="yellow" height="100" width="100" />
        </div>
      </Columns.Column>
    )
  )
}

export default Spinner