import React from 'react'
import {useState, useEffect} from 'react';
import '../viewPdf/ViewPdf.css';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Columns, Button } from 'react-bulma-components';

import NoContent from '../../components/noContent/NoContent';


const ViewPdf = (props) => {

  const file = useState(props.projectFile? props.projectFile : null);

  const backToProjectClicked = () => {
    props.func(true);
  };

  useEffect(() => {
    window.addEventListener("resize", toolbarChange());
    window.addEventListener("DOMContentLoaded", toolbarChange());
  })

  const toolbarChange = () => {
    document.getElementsByClassName('rpv-toolbar__left')[0]?.setAttribute('style', 'display: none');
    document.getElementsByClassName('rpv-toolbar__item')[12]?.setAttribute('style', 'display: none');
    document.getElementsByClassName('rpv-toolbar__item')[9]?.setAttribute('style', 'display: none');
    document.getElementsByClassName('rpv-toolbar__item')[8]?.setAttribute('style', 'display: none');
    document.getElementsByClassName('rpv-toolbar__item')[7]?.setAttribute('style', 'display: none');
    document.getElementsByClassName('rpv-toolbar__item')[10]?.parentElement.setAttribute("style", "display: flex ")
    document.getElementsByClassName('rpv-toolbar__item')[11]?.parentElement.setAttribute("style", "display: flex ")
  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });

  return (
    <div id='viewPdfPage'>
      <div>
      <Columns centered="true">
        <Columns.Column>
          <Button color='primary' onClick={() => backToProjectClicked()} >Go Back To Project</Button>
        </Columns.Column>
      </Columns>
      <Columns centered="true">
        <Columns.Column size={8}>
          {file===null ?
          <NoContent />
          :
          <div className="container">
            <div className="viewer">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                  <Viewer onDocumentLoad={(e) => toolbarChange()}
                    fileUrl={'/projects_pdf/' + file[0]}
                    plugins={[defaultLayoutPluginInstance]}>
                  </Viewer>
                </Worker>
            </div>
          </div>
          } 
        </Columns.Column>
      </Columns>
      <Columns centered="true">
        <Columns.Column>
          <Button color='primary' onClick={()=>backToProjectClicked()} >Go Back To Project</Button>
        </Columns.Column>
      </Columns>
      </div>
    </div>
  );
}
export default ViewPdf
